import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'

export const redirectToAccountOrLogin = (
  isB2B: boolean,
  isLoggedIn = false,
  pathname = '/account'
) => {
  if (isB2B) {
    return B2B.accountUrl
  }

  if (isLoggedIn) {
    return B2C.accountUrl
  }

  const url = `https://www.decathlon.com.br${pathname}#loginSuccess`
  const encodedUrl = encodeURIComponent(url)

  return `${B2C.loginUrl}?returnUrl=${encodedUrl}`
}
