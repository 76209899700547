import { Helmet } from 'react-helmet-async'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { getCMSPageDataByContentType } from 'src/cms/client'
import { slugify } from 'src/utils/slugfy'
import type { PropsCMS } from 'src/components/sports/utils/types'
import {
  ServicesPageTemplate,
  ServicesMenu,
} from 'src/components/servicos/ServicesPageTemplate'
import { getCMSPage } from 'src/utils/getCMSPage'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

function ServicesPage(props: PropsCMS) {
  const { serverData } = props

  if (serverData === null) {
    return null
  }

  const { cmsData, menuData } = serverData

  const menuCmsData = menuData?.sections?.find(
    (section) => section.name === 'Menu de serviços'
  )

  const seo = cmsData.seo.siteMetadataWithSlug

  return (
    <>
      <Helmet>
        <meta name="keywords" content={seo.keywords} />
        <meta property="og:image" content={seo.ogImage} />
      </Helmet>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        language="pt-br"
        canonical={`https://www.decathlon.com.br/servicos/${seo.slug}`}
        openGraph={{
          type: 'website',
          url: `https://www.decathlon.com.br/servicos/${seo.slug}`,
          title: seo.title,
          description: seo.description,
        }}
      />
      <section className="px-4 py-12 md:flex md:flex-row-reverse md:py-24 md:px-20 md:justify-between">
        {menuCmsData && !!menuCmsData?.data?.enableSession && (
          <ServicesMenu {...menuCmsData?.data} />
        )}
        <ServicesPageTemplate {...props} />
      </section>
    </>
  )
}

export const getServerData = async ({
  query,
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) => {
  const newSlug = slugify(slug, '+')

  const CMS_PAGE_ID = query?.cmsPageId ?? ''
  const CMS_VERSION_ID = query?.cmsVersionId ?? ''
  const CMS_MENU = query?.menu ?? 'false'

  if (CMS_PAGE_ID !== '' && CMS_VERSION_ID !== '') {
    const isB2B = checkEnviromentIsB2B()

    const servicesCmsPreview = await getCMSPage({
      contentType: 'services-page',
      documentId: CMS_PAGE_ID,
      versionId: CMS_VERSION_ID,
      builder: isB2B ? 'faststoreB2B' : 'faststore',
    })

    if (!servicesCmsPreview) {
      return {
        status: 301,
        props: null,
        headers: {
          location: `/404/?from=${slug}`,
        },
      }
    }

    const {
      props: { cmsData },
    } = servicesCmsPreview

    if (CMS_MENU === 'true') {
      const menuPreviewData = await getCMSPage({
        contentType: 'services-menu',
        documentId: '23a8b762-08d4-4d94-b11c-8f03edb56ce6',
        versionId: '4bca62f1-884b-4f9a-ac74-2c33d40732f3',
        builder: 'faststore',
      })

      const menuData = menuPreviewData?.props?.cmsData ?? null

      return {
        status: 200,
        props: {
          menuData,
          cmsData,
        },
        headers: {
          'cache-control': 'no-cache',
        },
      }
    }

    return {
      status: 200,
      props: {
        cmsData,
      },
      headers: {
        'cache-control': 'no-cache',
      },
    }
  }

  const servicesMenuCms =
    (await getCMSPageDataByContentType({ contentType: 'services-menu' })) ??
    null

  const servicesCms = await getCMSPageDataByContentType({
    contentType: 'services-page',
    params: {
      filters: { name: newSlug },
    },
  })

  if (!servicesCms) {
    return {
      status: 301,
      props: null,
      headers: {
        location: `/404/?from=${slug}`,
      },
    }
  }

  return {
    status: 200,
    props: {
      menuData: servicesMenuCms,
      cmsData: servicesCms,
    },
    headers: {
      'cache-control': 'no-cache',
    },
  }
}

export default ServicesPage
