import { Image } from 'src/components/ui/Image'
import FlexibleLink from 'src/components/ui/FlexibleLink/FlexibleLink'

import type { ImageComponentData } from '../types/image-component'

const getImageSize = (imagesLength: number) => {
  const sizes: Record<
    number,
    { width: number; height: number; style: string }
  > = {
    1: {
      width: 953,
      height: 423,
      style: 'grid-cols-1 grid-rows-1',
    },
    2: {
      width: 464.5,
      height: 289,
      style:
        'grid-cols-[1fr_1fr] md:grid-cols-[repeat(2,_464.5px)] grid-rows-1 gap-6',
    },
    3: {
      width: 301.5,
      height: 289,
      style:
        'grid-cols-2 md:grid-cols-[repeat(3,_301.5px)] grid-rows-2 md:grid-rows-1 gap-6',
    },
  }

  return (
    sizes[imagesLength] || {
      width: 0,
      height: 0,
      style: '',
    }
  )
}

const ServicesImageComponent = ({
  enableSession,
  images,
}: ImageComponentData) => {
  const imagesLength = images?.length ?? 0
  const { width, height, style } = getImageSize(imagesLength)

  return (
    <>
      {!!enableSession && (
        <section
          className={`max-w-[953px] mb-6 grid justify-between ${style} md:mb-16`}
        >
          {images?.map(({ image, altText, link }, index) => {
            if (!image) {
              return null
            }

            return link ? (
              <FlexibleLink linkUrl={link} key={`${altText} ${index}`}>
                <Image
                  src={image}
                  alt={altText}
                  width={width}
                  height={height}
                />
              </FlexibleLink>
            ) : (
              <Image src={image} alt={altText} width={width} height={height} />
            )
          })}
        </section>
      )}
    </>
  )
}

export default ServicesImageComponent
