import type { FeatureObject } from './types/amplitudeObjects'

const sectionsMap = {
  'Hero Banner [Home]': 'Banners carousel',
  ' Pictos [Home]': 'Pictos',
  'Encontre seu esporte [Home]': 'Encontre seu esporte',
  'Banners Secundários [Home]': 'Banners secundários',
  'Banners Terciários [Home]': ' Banners terciários',
  'Nossas Marcas [Home]': 'Nossas marcas',
}

/**
 * @param {string} section - CMS section Ex: Hero Banner [Home].
 * @param {string} locationOnPage - Ex: "top", "middle", "bottom".
 * @param {string} name - Product name for section "Banners Terciários [Home]" and img alt for all others.
 */
export function getFeatureObject({
  section,
  locationOnPage,
  name,
}: FeatureObject) {
  return {
    'location on page': locationOnPage,
    type:
      sectionsMap[section as keyof typeof sectionsMap] ??
      section?.replace(' [Home]', '').replace(' [Departamento]', ''),
    name,
  }
}
