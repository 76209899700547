import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'

type CashbackInformationModalProps = {
  setIsGiftcardModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const GiftcardInfoModal = ({
  setIsGiftcardModalOpen,
}: CashbackInformationModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    const eventTarget = event.target as Node | null

    if (modalRef.current === eventTarget) {
      setIsGiftcardModalOpen(false)
    }
  }

  useEffect(() => {
    if (!document) {
      return () => {}
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return createPortal(
    <div
      className="cashback-info-modal-backdrop bg-[#15181bcc] fixed z-[21] top-0 left-0 w-full h-full flex items-center justify-center"
      ref={modalRef}
    >
      <div className="bg-[#fff] w-full max-w-[347px] text-base text-[#101010] font-inter pt-[48px] px-[16px] pb-[32px] relative flex flex-col items-center justify-center rounded-md restructure-tablet:max-w-[409px]">
        <strong className="max-w-[251px] text-center mb-6 text-lg leading-[27px] restructure-tablet:max-w-[313px]">
          Ops, parece que você digitou um Vale-compra ou cartão presente
        </strong>

        <p className="max-w-[251px] text-center mb-6 text-base restructure-tablet:max-w-[313px]">
          Siga com a compra e adicione o código na{' '}
          <span className="text-[#3643BA] font-semibold">
            etapa de pagamento
          </span>{' '}
          para usar o crédito disponível.
        </p>

        <p className="max-w-[251px] text-center mb-6 text-base restructure-tablet:max-w-[313px]">
          Lembre-se que este meio só pode ser utilizado em carrinhos que só
          contenham produtos vendidos pela Decathlon.
        </p>

        <button
          className="max-w-[251px] w-full h-[64px] text-lg leading-[27px] font-semibold rounded-full border border-solid border-[#B3BAC3] restructure-tablet:max-w-[313px]"
          onClick={() => setIsGiftcardModalOpen(false)}
        >
          Entendi
        </button>

        <button
          className="absolute right-[8px] top-[8px] w-[48px] h-[48px] flex items-center justify-center"
          onClick={() => setIsGiftcardModalOpen(false)}
        >
          <CloseButtonIcon />
        </button>
      </div>
    </div>,
    document.body
  )
}

export default GiftcardInfoModal
