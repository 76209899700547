import { useCallback, useRef, useState } from 'react'
import { Picture } from 'src/components/ui/Image'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

import type { HeroBanner } from './types'
import { dispatchToDatalayer } from './utils'

interface Props extends HeroBanner {
  index: number
  inSlide?: boolean
  slideActive?: boolean
  section: string
  locationOnPage: string
}

const style = {
  container: {
    branco: 'bg-[#15181B]',
    preto: 'bg-[#EBEBEB]',
  },
  title: {
    branco: 'text-restructure-primary-inverted',
    preto: 'text-restructure-primary',
  },
  text: {
    branco: 'text-restructure-primary-inverted',
    preto: 'text-restructure-primary',
  },
} as const

export const Banner = ({
  title,
  image,
  linkUrl,
  text,
  index,
  inSlide = false,
  slideActive = false,
  locationOnPage,
  section,
}: Props) => {
  const standardObject = getStandardObject()
  const featureObject = getFeatureObject({
    section,
    locationOnPage,
    name: image?.altImage ?? '',
  })

  const { ref } = useInView({
    threshold: 0.7,
    onChange: (inView) => {
      if (!inView) {
        return
      }

      handleInview()
    },
  })

  const imageRef = useRef<HTMLImageElement | null>(null)

  const [hasBeenViewed, setHasBeenViewed] = useState(false)
  const [featureViewed, setFeatureViewed] = useState(false)

  const dispatchData = useCallback(() => {
    dispatchToDatalayer([
      {
        title,
        currentBannerImage: `${imageRef.current?.currentSrc}`,
        linkUrl,
      },
    ])
    setHasBeenViewed(true)
  }, [title, imageRef, linkUrl])

  const sendPromoFeatureViewedEvent = () => {
    if (!locationOnPage || !section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section,
        locationOnPage,
        name: image?.altImage ?? image?.descImage ?? '',
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })

    setFeatureViewed(true)
  }

  const handleInview = () => {
    if (!featureViewed) {
      sendPromoFeatureViewedEvent()
    }

    if (hasBeenViewed || !imageRef.current?.currentSrc) {
      return
    }

    if (!inSlide) {
      dispatchData()

      return
    }

    if (!slideActive) {
      return
    }

    dispatchData()
  }

  return (
    <div data-index={index} ref={ref}>
      <Link
        to={linkUrl ?? ''}
        title={title?.text}
        className={`relative block  ${
          style.container[title?.textColor ?? 'branco']
        } !p-0`}
        data-testid="individual-banner"
        onClick={() =>
          dispatchAmplitudeEvent({
            eventName: 'Promotional Element Selected',
            eventData: {
              ...standardObject,
              ...featureObject,
              'promotional element name': image?.descImage ?? '',
              'promotional element index': index ?? 0,
            },
          })
        }
      >
        <Picture
          data-banner-type="Carousel Home"
          sources={[
            {
              src: image?.mobile ?? '',
              width: 360,
              height: 480,
              media: '(max-width: 766px)',
            },
            {
              src: image?.tablet ?? '',
              width: 766,
              height: 409,
              media: '(max-width: 900px)',
            },
            {
              src: image?.desktop ?? '',
              width: 1440,
              height: 623,
              media: '(min-width: 901px)',
            },
          ]}
          img={{
            src: image?.desktop ?? '',
            width: 1440,
            height: 623,
            'data-id': image?.descImage ?? '',
            title: image?.descImage ?? '',
            alt: image?.altImage ?? '',
            loading: !index ? 'eager' : 'lazy',
            fetchpriority: !index ? 'high' : 'auto',
            ref: imageRef,
          }}
        />
        <div className="absolute z-10 left-0 bottom-0 w-full">
          <div className="mx-4 restructure-tablet:ml-5 w-full flex flex-col items-start max-w-[328px] restructure-tablet:max-w-[492px] restructure-small-desktop:max-w-[627px] restructure-small-desktop:ml-20">
            <h2
              className={`desktop-heading-display tablet-mobile-heading-title2 mobile-heading-title2 ${
                style.title[title?.textColor ?? 'branco']
              }
          w-full
          h-auto
          restructure-tablet:w-[391px]
          restructure-small-desktop:w-[627px]
          `}
            >
              {title?.text}
            </h2>
            {text?.text !== '' && (
              <h3
                className={`${
                  style.text[text?.textColor ?? 'branco']
                } desktop-heading-title5 tablet-heading-title5 mobile-heading-title5  restructure-small-desktop:pr-5 block
              mt-[8px]
              w-[328px]
              h-auto
              restructure-tablet:w-[391px]
              restructure-small-desktop:w-[518px]
              restructure-small-desktop:mt-[16px]`}
              >
                {text?.text}
              </h3>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}
