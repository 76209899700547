import { lazy, Suspense, useEffect } from 'react'
import type { ReactElement } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet-async'
import { useUI } from 'src/sdk/ui'
import Header from 'src/components/common/Header'
import { CepInformationProvider } from 'src/components/common/CepComponent/hooks/CepInformationContext'
import { useLocation } from '@reach/router'
import Cookies from 'js-cookie'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'

import { usePageViewEvent } from './utils/gtmEvents'
import { UserDataProvider } from './components/contexts/UserDataContext'
import { ThemeProvider } from './components/contexts/ThemeContext'
import { useLinxOtherView } from './sdk/linx/hooks/useLinxOtherView'
import { GlobalProvider } from './components/global/context/global-context'
import Footer from './components/restructure/common/Footer'
import AccountFooter from './components/account/Footer'
import { AccountProvider } from './components/account/context'
import { WishlistProvider } from './components/contexts/wishlist-context'
import { CheckoutProvider } from './sdk/checkout/context/checkout'
import InitialPopUps from './components/PopUps/InitialPopUps'
import { ModalSheet } from './components/ui/ModalSheet/ModalSheet'
import { getLoginOriginType } from './utils/amplitude/getLoginOriginType'
import CashbackStripeLogedIn from './components/Cashback/CashbackStripe/CashbackStripeLogedIn'

const CartSidebar = lazy(() => import('src/components/cart/CartSidebar'))

type LayoutProps = {
  children: ReactElement
}

function Layout({ children }: LayoutProps) {
  const { displayMinicart } = useUI()
  const { sendOtherViewEvent } = useLinxOtherView()
  const [mode] = useQueryParam('mode', StringParam)
  const { pathname, href } = useLocation()

  const standardObj = getStandardObject()

  const hideHeader = ['member', 'checkout', 'login'].some((item) =>
    pathname.includes(item)
  )

  const isAccountFooter = pathname.includes('account')

  const url = href ? new URL(href) : null

  const paramOrderFormId = url?.searchParams.get('orderFormId')

  usePageViewEvent()

  useEffect(() => {
    if (!children) {
      return
    }

    const pathPage = (children as ReactElement)?.props?.children?.[1]?.key

    sendOtherViewEvent(pathPage)
  }, [sendOtherViewEvent, children])

  useEffect(() => {
    const el = document.getElementsByClassName('chatbot-sc-launcher')

    if (pathname !== '/atendimento' && el.length > 0) {
      el[0].remove()
    }
  }, [pathname])

  useEffect(() => {
    const isLoginSuccess = window.location.href.includes('loginSuccess')

    if (!isLoginSuccess) {
      return
    }

    const loginOriginPage = Cookies.get('loginURL')

    dispatchAmplitudeEvent({
      eventName: 'Login Completed',
      eventData: {
        ...standardObj,
        'login method': 'email',
        'login trigger': getLoginOriginType(loginOriginPage),
      },
    })
  }, [standardObj])

  useEffect(() => {
    dispatchAmplitudeEvent({
      eventName: 'Page Viewed',
      eventData: standardObj,
    })
  }, [pathname, standardObj])

  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content="https://decathlonstore.vteximg.com.br/arquivos/logo_site_tag_og2.png"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        <meta
          property="og:site_name"
          content="Decathlon a maior loja de artigos esportivos"
        />
      </Helmet>
      <UserDataProvider>
        <ThemeProvider>
          <CepInformationProvider>
            <GlobalProvider>
              <AccountProvider>
                <WishlistProvider>
                  <CheckoutProvider paramOrderFormId={paramOrderFormId}>
                    {mode !== 'app' && !hideHeader && <Header />}
                    {mode !== 'app' && (
                      <CashbackStripeLogedIn
                        showStripe={
                          pathname !== '/' && !pathname.includes('cart')
                        }
                      />
                    )}

                    <>{children}</>

                    {mode !== 'app' && !hideHeader && (
                      <>{isAccountFooter ? <AccountFooter /> : <Footer />}</>
                    )}

                    <InitialPopUps isApp={mode === 'app'} />

                    <noscript>
                      <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-T777CVM"
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                        title="Google Tag Manager iframe"
                      />
                    </noscript>

                    {displayMinicart && (
                      <Suspense fallback={null}>
                        <CartSidebar />
                      </Suspense>
                    )}

                    <ModalSheet />
                    <div id="doris-widget" />
                  </CheckoutProvider>
                </WishlistProvider>
              </AccountProvider>
            </GlobalProvider>
          </CepInformationProvider>
        </ThemeProvider>
      </UserDataProvider>
    </>
  )
}

export default Layout
