import type { ComponentType } from 'react'
import type { ServicesSections } from 'src/components/servicos/types/sections'

import ServicesVideoComponent from './ServicesVideoComponent/ServicesVideoComponent'
import ServicesTextComponent from './ServicesTextComponent'
import ServicesDividerComponent from './ServicesDividerComponent'
import ServicesCallToAction from './ServicesCallToAction/ServicesCallToAction'
import ServicesImageComponent from './ServicesImageComponent'

interface ServerDataServicePage {
  cmsData: {
    sections: ServicesSections[]
  }
}

interface ServicesPageTemplateProps {
  serverData: ServerDataServicePage
}

export function ServicesPageTemplate(props: ServicesPageTemplateProps) {
  const COMPONENTS: Record<string, ComponentType<any>> = {
    '[Serviços] Bloco de Video': ServicesVideoComponent,
    '[Serviços] Bloco de Texto': ServicesTextComponent,
    '[Serviços] Bloco de Quebra de Página': ServicesDividerComponent,
    '[Serviços] Bloco de CTA': ServicesCallToAction,
    '[Serviços] Bloco de Imagens': ServicesImageComponent,
  }

  const { cmsData } = props.serverData

  if (!cmsData) {
    return null
  }

  const { sections } = cmsData

  return (
    <div>
      {sections?.map(({ name, data }, index) => {
        const Component = COMPONENTS[name]

        if (!Component) {
          console.info(
            `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
          )

          return <></>
        }

        return (
          <Component
            key={`cms-section-${name}-${index}`}
            {...{ ...data, section: name }}
          />
        )
      })}
    </div>
  )
}
