interface ButtonIconProp extends React.HTMLAttributes<HTMLButtonElement> {
  link?: string
  children: React.ReactNode
  badgeValue?: number | string
  badgeColor?: string
  badgeTextColor?: string
  enableHover?: boolean
  onClick?: () => void
  setCookie?: () => void
}

export function ButtonIcon({
  link,
  badgeValue,
  badgeColor = 'restructure-background-blue',
  badgeTextColor = 'white',
  enableHover = true,
  children,
  onClick,
  setCookie,
  ...rest
}: ButtonIconProp) {
  const handleButtonClick = () => {
    setCookie?.()

    if (link) {
      window.location.href = link
    }
  }

  const handleClick = onClick ?? handleButtonClick

  return (
    <button
      className={`${
        enableHover &&
        'hover:bg-neutral02 hover:rounded-full focus:bg-neutral03 focus:rounded-full'
      }  restructure-small-desktop:w-[48px] restructure-small-desktop:h-[48px] flex items-center justify-center relative p-2`}
      onClick={handleClick}
      {...rest}
    >
      {!!badgeValue && (
        <span
          className={`bg-${badgeColor} text-${badgeTextColor} font-medium absolute right-0 top-0 restructure-small-desktop:right-[4px] restructure-small-desktop:top-1 w-4 h-4 restructure-small-desktop:w-[20px] restructure-small-desktop:h-[20px] pl-[1px] flex items-center justify-center  mobile-overline-medium desktop-overline-medium  rounded-full`}
        >
          {badgeValue}
        </span>
      )}
      {children}
    </button>
  )
}
