/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { GetServerDataProps, PageProps } from 'gatsby'
import type {
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables,
} from '@generated/graphql'
import type { SearchState } from '@faststore/sdk'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import getCollectionServerSideData from 'src/utils/plp/getCollectionServerSideData'

type ServerData = {
  serverData: {
    searchParams: SearchState
    searchResult: SearchResult
  }
}

export type Props = PageProps<
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables
> &
  ServerData

function Page(props: Props) {
  const {
    data: { site, allCmsCollectionPage },
    serverData: { searchParams, searchResult },
  } = props

  const title = 'Search Results | FastStore'
  const EVENT_API_BASE_PATH = 'https://sp.vtex.com'

  if (!searchParams) {
    return null
  }

  const collection = allCmsCollectionPage.nodes
    .find((node) => node.name === 'Páginas de Coleção')
    ?.sections[0].data?.contents?.find(
      (item: any) => item.code === searchParams.term
    )

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        language="pt-br"
        title={collection?.titlePage ?? site?.siteMetadata?.title}
        description={
          collection?.titlePage ?? site?.siteMetadata?.description ?? ''
        }
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
        linkTags={[
          {
            rel: 'preconnect',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
          {
            rel: 'dns-prefetch',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
        ]}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto ">
        <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4 mb-6">
          <Breadcrumb
            type="single"
            breadcrumbList={[
              {
                item: `/collection?q=${collection?.name ?? searchParams.term}`,
                name: collection?.name ?? searchParams.term ?? '',
                position: 1,
              },
            ]}
          />
        </div>
      </div>

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      {/* <ProductGalleryHeading>
        PRODUTOS PARA COLEÇÃO{'  '}
        <span className="text-blue">
          {collection?.name ?? searchParams.term}
        </span>
      </ProductGalleryHeading> */}

      <ProductGalleryV2
        bannerDesktop={collection?.bannerDesktop}
        bannerMobile={collection?.bannerMobile}
        title={searchResult?.collection?.name ?? ''}
        type="collection"
        pageType="página de lista de produto"
        searchResult={searchResult}
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchCollectionPage {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
    allCmsCollectionPage {
      nodes {
        id
        name
        sections {
          data
          id
          name
        }
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)

export const getServerData = async (props: GetServerDataProps) => {
  const { query } = props

  try {
    const { searchParams, searchResult } = await getCollectionServerSideData({
      slug: 'collection',
      query,
    })

    return {
      status: 200,
      props: {
        searchResult,
        searchParams,
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 's-maxage=31536000, stale-while-revalidate',
      },
    }
  }
}
