import { useEffect, useState, createContext, useContext } from 'react'

import FooterContent from './FooterContent'
import Navigation from './Navigation'

type NavigationContextType = {
  heightContainer: number | null
}

const NavigationContext = createContext<NavigationContextType>({
  heightContainer: null,
})

export const useNavigation = () => useContext(NavigationContext)

const headerMobileHeight = 129

interface MenuContentProps {
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function MenuContent({ setMenuIsOpen }: MenuContentProps) {
  const [heightViewport, setHeightViewport] = useState(736)

  useEffect(() => {
    const setHeightContainer = () => {
      if (visualViewport) {
        setHeightViewport(visualViewport.height)
      }
    }

    setHeightContainer()

    visualViewport?.addEventListener('resize', setHeightContainer)

    return () =>
      visualViewport?.removeEventListener('resize', setHeightContainer)
  }, [])

  const heightContainer = heightViewport - headerMobileHeight

  return (
    <NavigationContext.Provider value={{ heightContainer }}>
      <article className="fixed top-0 h-[100vh] w-full z-10 bg-white border-t border-lightestBlue overflow-hidden max-h-[88%] !max-h-[100dvh]">
        <div className="relative w-full h-full">
          <div className="flex flex-col overflow-y-auto overflow-x-hidden h-full w-full scrollbar-v2">
            <Navigation setMenuIsOpen={setMenuIsOpen} />
            <FooterContent setMenuIsOpen={setMenuIsOpen} />
          </div>
        </div>
      </article>
    </NavigationContext.Provider>
  )
}

export default MenuContent
