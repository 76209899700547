import type { DividerComponentData } from '../types/divider-component'

const ServicesDividerComponent = ({ enableSession }: DividerComponentData) => {
  return (
    <>
      {enableSession && (
        <div className="h-16 max-w-[953px] border-t-2 border-solid border-[#C3C9CF]" />
      )}
    </>
  )
}

export default ServicesDividerComponent
