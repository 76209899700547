import { useCallback, useEffect } from 'react'
import DOMPurify from 'dompurify'
import { useLocation } from '@reach/router'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'

import storeConfig from '../../../../store.config'
import './maybeCashback.scss'

const href = `${storeConfig.loginUrl}?returnUrl=https://www.decathlon.com.br?showcashbackpopup=true`

type MaybeCashbackProps = {
  cashbackStipeMainText: string
  cashbackStipeSecondText: string
}

const MaybeCashback = ({
  cashbackStipeMainText,
  cashbackStipeSecondText,
}: MaybeCashbackProps) => {
  const { pathname } = useLocation()

  const sanitizedCashbackStipeMainText = DOMPurify.sanitize(
    cashbackStipeMainText
  )

  const sanitizedCashbackStipeSecondText = DOMPurify.sanitize(
    cashbackStipeSecondText
  )

  const featureObject = getFeatureObject({
    locationOnPage: 'Home',
    name: 'cashback deslogado',
    section: 'stripe cashback',
  })

  const sendPromoFeatureViewedEvent = useCallback(() => {
    const eventData = {
      ...getStandardObject(),
      ...featureObject,
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }, [featureObject])

  useEffect(() => {
    if (pathname !== '/') {
      return
    }

    sendPromoFeatureViewedEvent()
  }, [pathname, sendPromoFeatureViewedEvent])

  if (pathname !== '/') {
    return null
  }

  function sendPromoFeatureClickEvent() {
    const eventData = {
      ...getStandardObject(),
      ...featureObject,
    }

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData,
    })
  }

  return (
    <section className="maybe-cashback-stripe text-restructure-primary  bg-restructure-background-neutral-3 flex flex-col md:flex-row p-4 md:py-5 md:px-2 items-center justify-center gap-3 md:gap-10 text-center md:text-left">
      <div>
        <p
          className="text-base font-bold md:text-lg "
          dangerouslySetInnerHTML={{ __html: sanitizedCashbackStipeMainText }}
        />
        <p
          className="text-xs md:text-sm font-medium"
          dangerouslySetInnerHTML={{ __html: sanitizedCashbackStipeSecondText }}
        />
      </div>

      <a
        className="text-xs md:text-base font-semibold w-[171px] h-8 md:w-[277px] md:h-12 flex items-center justify-center bg-restructure-background-primary-inverted text-white rounded-round"
        href={href}
        onClick={() => sendPromoFeatureClickEvent()}
      >
        Consultar meu saldo
      </a>
    </section>
  )
}

export default MaybeCashback
