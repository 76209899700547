import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'
import Cashback from 'src/images/icons/Cashback'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import {
  checkPageType,
  getStandardObject,
} from 'src/utils/amplitude/getStandardObject'

type CashbackPopupProps = {
  hancleClosePopup: () => void
}

export default function CashbackPopup({
  hancleClosePopup,
}: CashbackPopupProps) {
  const { pathname } = useLocation()
  const modalRef = useRef<HTMLDivElement>(null)
  const standardObject = getStandardObject()
  const featureObject = getFeatureObject({
    locationOnPage: checkPageType(pathname),
    name: 'logado sem saldo',
    section: 'modal cashback',
  })

  const handleClickOutside = (event: MouseEvent) => {
    const eventTarget = event.target as Node | null

    if (modalRef.current === eventTarget) {
      hancleClosePopup()
    }
  }

  useEffect(() => {
    if (!document) {
      return () => {}
    }

    sendPromoFeatureViewedEvent()

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  function sendPromoFeatureViewedEvent() {
    const eventData = {
      ...standardObject,
      ...featureObject,
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  function sendPromoFeatureClickEvent() {
    hancleClosePopup()
    const eventData = {
      ...standardObject,
      ...featureObject,
    }

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData,
    })
  }

  return createPortal(
    <section
      className="cashback-popup text-restructure-primary bg-[#15181bcc] fixed z-[10000] top-0 left-0 w-full h-full flex items-end md:items-center justify-center"
      ref={modalRef}
    >
      <div className="w-full md:max-w-[375px] h-[395px] bg-white relative">
        <button onClick={hancleClosePopup} className="absolute top-5 right-5">
          <CloseButtonIcon />
        </button>

        <div className="p-8 mt-8 flex flex-col justify-center items-center text-center">
          <div className="shadow-[0_4px_83.1px_29px_rgba(54,67,186,0.3)] bg-white rounded-[100%] w-16 h-16 flex justify-center items-center">
            <Cashback color="#3643BA" size={40} />
          </div>

          <p className="text-xl font-medium mt-8">
            Que pena! Você ainda não <br />
            possui cashback disponível :(
          </p>
          <p className="text-sm font-medium mt-2 text-tertiary">
            Explore nossa lista de produtos <br />
            que geram cashback
          </p>

          <Link
            className="mt-12  w-[311px] h-[48px] bg-restructure-background-blue text-white text-base font-semibold rounded-round flex items-center justify-center"
            to="/collection/?q=923"
            onClick={() => sendPromoFeatureClickEvent()}
          >
            Conferir produtos
          </Link>
        </div>
      </div>
    </section>,
    document.body
  )
}
