import { useStaticQuery, graphql } from 'gatsby'

type Category = {
  title?: string
  url?: string
}

export type Pictograma =
  | 'Mais Vendidos'
  | 'Novidades'
  | 'Roupa'
  | 'Calçados'
  | 'Acessórios'
  | 'Esportes em destaque'
  | 'Todos os Esportes'
  | 'Alvo'
  | 'Aquáticos do Mar'
  | 'Aquáticos na Piscina'
  | 'Ciclismo'
  | 'Coletivos'
  | 'Deslize Urbano'
  | 'Dança'
  | 'Luta'
  | 'Montanha, Camping e Trilha'
  | 'Musculação & Fitness'
  | 'Pilates e Yoga'
  | 'Raquetes'
  | 'Esportes ao Ar Livre'
  | 'Para Brincar'
  | 'Kit iniciação'
  | 'Manutenção'
  | 'Apoio'

export type PossibleThirdLevelLayout =
  | '3.1 Bloco de marcas'
  | '3.2 Editorial - liquidação'
  | '3.3 Editorial - OPECO'
  | '3.4 Grupo de esportes'
  | '3.5 Listagem simples'
  | '3.6 Bloco de banners'

export type PossibleSportsGroupLayout =
  | '1.1 Blocos'
  | '1.2 Blocos + Blocos retangulares'

export type SubcategoryBasedOnType =
  | Level3BrandBlock
  | Level3EditorialLiquidation
  | Level3EditorialOpeco
  | Level3SportsGroup
  | Level3SimpleListing
  | Level3BannerBlock

export type Level3BrandBlock = Category & {
  brand: string
  image: string
}

export type Level3EditorialLiquidation = {
  url: string
  title: string
  tag?: string
  subtítulo?: string
  image?: string
  options_01?: string
  urlOptions_01?: string
  new_tab_01?: string

  options_02?: string
  urlOptions_02?: string
  new_tab_02?: string

  options_03?: string
  urlOptions_03?: string
  new_tab_03?: string

  options_04?: string
  urlOptions_04?: string
  new_tab_04?: string
}

export type Level3EditorialOpeco = {
  url: string
  title: string
  image: string
  options_01?: string
  urlOptions_01?: string
  new_tab_01?: string

  options_02?: string
  urlOptions_02?: string
  new_tab_02?: string

  options_03?: string
  urlOptions_03?: string
  new_tab_03?: string

  options_04?: string
  urlOptions_04?: string
  new_tab_04?: string
}

export interface BlockCategory extends Category {
  imageCategorie: string
}

export interface Level3SportsGroup extends Category {
  // Defina as propriedades específicas para '3.4 Grupo de esportes' aqui
  selectTypeThirtLevel: PossibleSportsGroupLayout
  blockCategories?: BlockCategory[]
  itemList?: BlockCategory[]
  new_tab?: boolean
  banner?: string
}

export type Level3SimpleListing = Category & {
  new_tab: boolean
}

export type Level3BannerBlock = Category & {
  banner: string
  new_tab: boolean
}

export interface Level2 extends Category {
  highlight: boolean
  picto: Pictograma
  subcategories?: SubcategoryBasedOnType[]
  typeThirtLevel: PossibleThirdLevelLayout
  department?: string
}

export interface Level1 extends Category {
  new_tab?: boolean
  imageBannerDesktop: string
  imageBannerMobile: string
  linkBanner?: string
  categories?: Level2[]

  new_tabBanner?: boolean
}
export interface DataMenu {
  departments: Level1[]
}

type GetMenuData = {
  allCmsHome: {
    nodes: Array<{
      id: string
      sections: Array<{ data: DataMenu; id: string | null }>
    }>
  }
}

export const MenuQuery = graphql`
  query GetMenuData {
    allCmsHome(filter: { id: { eq: "75da59ee-1ba5-5552-87da-b70c4e52fd94" } }) {
      nodes {
        id
        sections {
          data
          id
        }
      }
    }
  }
`

export function useMenu() {
  return useStaticQuery<GetMenuData>(MenuQuery)
}
