import { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { InView } from 'react-intersection-observer'
import { useAccountContext } from 'src/components/account/context'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import {
  checkPageType,
  getStandardObject,
} from 'src/utils/amplitude/getStandardObject'
import { maskCurrency } from 'src/utils/convertCurrency'

type CashbackStripeLogedInProps = {
  showStripe: boolean
}
export default function CashbackStripeLogedIn({
  showStripe = false,
}: CashbackStripeLogedInProps) {
  const { pathname } = useLocation()
  const { cashBackBalance } = useAccountContext()
  const [hasViewedCashbackInfo, setHasViewedCashbackInfo] = useState(false)
  const standardObject = getStandardObject()
  const featureObject = getFeatureObject({
    locationOnPage: checkPageType(pathname),
    name: 'logado com saldo',
    section: 'stripe cashback',
  })

  useEffect(() => {
    setHasViewedCashbackInfo(!!sessionStorage.getItem('cashback-stripe'))
  }, [])

  if (!showStripe || !cashBackBalance || hasViewedCashbackInfo) {
    return null
  }

  const handleClick = () => {
    sessionStorage.setItem('cashback-stripe', 'hide')
    setHasViewedCashbackInfo(true)
    sendPromoFeatureClickEvent()
  }

  function sendPromoFeatureViewedEvent() {
    const eventData = {
      ...standardObject,
      ...featureObject,
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  function sendPromoFeatureClickEvent() {
    const eventData = {
      ...standardObject,
      ...featureObject,
    }

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData,
    })
  }

  return (
    <InView
      as="section"
      threshold={0.7}
      onChange={(inView) => {
        if (inView) {
          sendPromoFeatureViewedEvent()
        }
      }}
      triggerOnce
      className="logedin-cashback-stripe text-restructure-tertiary  bg-restructure-background-neutral-3 flex flex-col justify-center items-center p-4 md:py-5 md:px-2"
    >
      <p className="font-bold text-base md:text-lg">
        Você tem{' '}
        <span className="text-restructure-action">
          {maskCurrency(cashBackBalance)}
        </span>{' '}
        de cashback disponível!
      </p>
      <p className="text-xs md:text-sm font-medium">
        Utilize antes da data limite de expiração.{' '}
        <button onClick={handleClick} className="underline">
          Entendi
        </button>
      </p>
    </InView>
  )
}
