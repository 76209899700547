import React, { useState } from 'react'

type AccordionProps = {
  title: string
  children: React.ReactNode
  closedIcon: React.ReactNode
  openIcon: React.ReactNode
  className?: string
}

const Accordion = ({
  title,
  children,
  closedIcon,
  openIcon,
  className,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div
      className={`mb-[72px] pb-6 border-b border-solid border-[#D9DDE1] ${className}`}
    >
      <button
        onClick={toggleAccordion}
        className="flex w-full items-center justify-between h-8"
      >
        <span className="text-xl font-medium text-[#101010]">{title}</span>
        <span>{isOpen ? openIcon : closedIcon}</span>
      </button>
      {isOpen && <div className="mt-6">{children}</div>}
    </div>
  )
}

export default Accordion
