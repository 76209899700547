import FlexibleLink from 'src/components/ui/FlexibleLink/FlexibleLink'

import type { CtaComponentProps } from '../../types/cta-component'

import './serviceCallToAction.scss'

const ServicesCallToAction = ({
  enableSession,
  linkURL,
  text,
}: CtaComponentProps) => {
  if (!enableSession) {
    return null
  }

  return (
    <div className="services-call-to-action-container">
      <FlexibleLink label={text} linkUrl={linkURL} />
    </div>
  )
}

export default ServicesCallToAction
