import type { BreadcrumbList } from 'src/components/product-page/types'

export const formattedMoney = (
  price: string,
  locale: string,
  currency: string
) => {
  const priceNum = Number(price.replace('$', '').replace(',', ''))

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .format(priceNum)
    .replace('R', '')
}

export const capitalize = (word: string, mode: string) => {
  const untreatedString = word?.replaceAll('-', ' ')
  const treatedString = untreatedString.split(' ')

  if (mode === 'breadcrumb') {
    const capitalizedStrings = treatedString.map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1)
    })

    return capitalizedStrings.join(' ')
  }

  return treatedString[0].charAt(0).toUpperCase() + treatedString[0].slice(1)
}

export const getInstallment = (
  price: number
): { installmentPrice: string; installment: number } => {
  if (price >= 999.99) {
    return {
      installmentPrice: (price / 10).toFixed(2).replace('.', ','),
      installment: 10,
    }
  }

  if (price >= 899.99) {
    return {
      installmentPrice: (price / 9).toFixed(2).replace('.', ','),
      installment: 9,
    }
  }

  if (price >= 799.99) {
    return {
      installmentPrice: (price / 8).toFixed(2).replace('.', ','),
      installment: 8,
    }
  }

  if (price >= 699.99) {
    return {
      installmentPrice: (price / 7).toFixed(2).replace('.', ','),
      installment: 7,
    }
  }

  if (price >= 299.99) {
    return {
      installmentPrice: (price / 6).toFixed(2).replace('.', ','),
      installment: 6,
    }
  }

  if (price >= 249.99) {
    return {
      installmentPrice: (price / 5).toFixed(2).replace('.', ','),
      installment: 5,
    }
  }

  if (price >= 199.99) {
    return {
      installmentPrice: (price / 4).toFixed(2).replace('.', ','),
      installment: 4,
    }
  }

  return {
    installmentPrice: (price / 2).toFixed(2).replace('.', ','),
    installment: 2,
  }
}

export const getProductNameLines = (prodName: string) => {
  const screenSize = window.screen.width

  if (screenSize > 0 && screenSize <= 375) {
    return prodName.length > 26
  }

  if (screenSize > 375 && screenSize <= 425) {
    return prodName.length > 32
  }

  if (screenSize > 425 && screenSize <= 768) {
    return prodName.length > 66
  }

  if (screenSize > 768 && screenSize <= 1024) {
    return prodName.length > 46
  }

  if (screenSize > 1024 && screenSize <= 1280) {
    return prodName.length > 42
  }

  return prodName.length > 65
}

export const transformFirstLetterToUppercase = (category: string) => {
  return category.replace(/(^\w{1})|(\s+\w{1})|(\/+\w{1})/g, (letter) =>
    letter.toUpperCase()
  )
}

export const getCorrectedCategory = (
  breadcrumbList: BreadcrumbList['itemListElement']
) => {
  const list = breadcrumbList.map((item) =>
    item.item.split('/').filter((name) => name !== '')
  )

  const filteredList = list.filter(
    (name) =>
      !name.includes('p') && !name.find((item) => item.includes('desativada'))
  )

  filteredList.sort((a, b) => b.length - a.length)
  const joinCategories = filteredList[0].join(' > ').replaceAll('-', ' ')

  return transformFirstLetterToUppercase(joinCategories).replace(
    /(\s+\S+\s)/g,
    (letter) => (letter.length < 4 ? letter.toLowerCase() : letter)
  )
}

export const formatCategoriesForEvent = (categories: string) => {
  if (categories === undefined) {
    return ''
  }

  return categories.split('/').slice(1, -1).join(' > ')
}
