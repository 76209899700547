import { useLocation } from '@reach/router'
import Accordion from 'src/components/ui/Accordion/Accordion'
import { ChevronDown } from 'src/components/Icons/ChevronDown'
import { ChevronUp } from 'src/components/Icons/ChevronUp'
import FlexibleLink from 'src/components/ui/FlexibleLink/FlexibleLink'

type MenuItem = {
  label: string
  linkUrl: string
}

type ServicesMenuProps = {
  title: string
  menuItems: MenuItem[]
}

const MenuContent = ({ menuItems }: { menuItems: MenuItem[] }) => {
  const { pathname } = useLocation()

  return (
    <div className="flex flex-col gap-3">
      {menuItems.map(({ label, linkUrl }, index) => {
        const isActive = pathname?.includes(linkUrl)

        const classNames = isActive
          ? 'text-base text-[#3643BA] font-semibold'
          : 'text-base text-[#101010]'

        return (
          <FlexibleLink
            key={`${label}-${index}`}
            label={label}
            linkUrl={linkUrl}
            classNames={classNames}
          />
        )
      })}
    </div>
  )
}

export const ServicesMenu = ({ title, menuItems }: ServicesMenuProps) => {
  return (
    <div>
      <div className="px-6 border-l border-solid border-[#E1E4E7] hidden md:flex md:flex-col">
        <h2 className="text-xl font-medium text-[#15181B] mb-8">{title}</h2>
        <MenuContent menuItems={menuItems} />
      </div>

      <Accordion
        title={title}
        openIcon={<ChevronUp />}
        closedIcon={<ChevronDown />}
        className="flex flex-col md:hidden"
      >
        <MenuContent menuItems={menuItems} />
      </Accordion>
    </div>
  )
}
