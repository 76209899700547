import axios from 'axios'

import type { GetShipping } from '../types/index.type'

export async function getShippingData({
  variables,
  sellers,
}: GetShipping): Promise<ShippingItem[]> {
  const shippingsSellers = sellers.map((seller) =>
    axios.post('/api/shipping', {
      items: variables?.items?.map((item) => ({
        id: item.itemId,
        quantity: item.quantity,
        seller,
      })),
      postalCode: variables.postalCode,
      country: variables.country,
    })
  )

  const responses = await Promise.all(shippingsSellers)

  return responses.map((item) => item.data) as ShippingItem[]
}
