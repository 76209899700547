/**
 * Component to render rich text content using Draft.js.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.text] - The raw Draft.js content state as a JSON string.
 * @returns {JSX.Element} The rendered rich text content.
 */
import type { RawDraftContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import DOMPurify from 'dompurify'
import './rich-text.scss'

function RichText({ text }: { text?: string }): JSX.Element {
  if (typeof window === `undefined` || !text) {
    return <></>
  }

  const parsedText = JSON.parse(text) as RawDraftContentState

  const markupContent = draftToHtml(parsedText)

  const sanitizedContent = DOMPurify.sanitize(markupContent)

  return (
    <div
      className="rich-text-container"
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  )
}

export default RichText
