import HamburgerMenuIcon from 'src/images/icons/HamburgerMenuIcon'
import { useMobile } from 'src/hooks/useMobile'

import type { SidebarMenuProps } from '../../SideBarMenu'
import { ButtonIcon } from '../../ButtonIcon'
import { CashbackMessage } from '../../Cashback/CashbackMessage'

interface OpenMenuButtonProps extends SidebarMenuProps {
  onClick: () => void
}

const OpenMenuButton = ({
  onClickLogin,
  onClick,
  showCashbackMessage,
  handleCloseNotification,
  badgeConfig,
}: OpenMenuButtonProps) => {
  const { isMobile } = useMobile()

  return (
    <div className={showCashbackMessage ? 'z-[1000000] relative' : ''}>
      <div className="w-12 h-12 bg-white flex items-center justify-center">
        <ButtonIcon
          aria-label="botão abrir menu mobile"
          enableHover={!showCashbackMessage}
          onClick={!showCashbackMessage ? onClick : undefined}
          {...badgeConfig}
        >
          <HamburgerMenuIcon />
        </ButtonIcon>
      </div>

      {showCashbackMessage && isMobile && (
        <CashbackMessage
          isMobile
          handleCloseNotification={handleCloseNotification}
          onClick={onClickLogin}
        />
      )}
    </div>
  )
}

export default OpenMenuButton
