import { Helmet } from 'react-helmet-async'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { getCMSPageDataByContentType } from 'src/cms/client'
import { slugify } from 'src/utils/slugfy'
import { SportsPageTemplate } from 'src/components/sports/SportsPageTemplate'
import { DepartmentPageTemplate } from 'src/components/department/DepartmentPageTemplate'
import type { PropsCMS } from 'src/components/sports/utils/types'
import type { SearchState } from '@faststore/sdk'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'
import getSportsPageServerSideData from 'src/utils/plp/getSportsPageServerSideData'

type ServerData = {
  serverData: {
    searchParams: SearchState
    searchResult: SearchResult
  }
}

function SportsPage(props: PropsCMS & ServerData) {
  const { serverData } = props

  if (serverData === null) {
    return null
  }

  const { cmsData } = serverData

  const seo = cmsData.seo.siteMetadataWithSlug

  return (
    <>
      <Helmet>
        <meta name="keywords" content={seo.keywords} />
        <meta property="og:image" content={seo.ogImage} />
      </Helmet>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        language="pt-br"
        canonical={`https://www.decathlon.com.br/esportes/${seo.slug}`}
        openGraph={{
          type: 'website',
          url: `https://www.decathlon.com.br/esportes/${seo.slug}`,
          title: seo.title,
          description: seo.description,
        }}
      />
      {cmsData.type === 'department-page' && (
        <DepartmentPageTemplate {...props} />
      )}
      {cmsData.type === 'esportes' && (
        <SportsPageTemplate {...props} type="página de lista de produto" />
      )}
    </>
  )
}

export const getServerData = async ({
  params: { slug },
  query,
}: {
  query: Record<string, string>
  params: Record<string, string>
}) => {
  const newSlug = slugify(slug, '+')

  const departmentCms = await getCMSPageDataByContentType({
    contentType: 'department-page',
    params: {
      filters: { name: newSlug },
    },
  })

  const sportsCms = await getCMSPageDataByContentType({
    contentType: 'esportes',
    params: {
      filters: { name: newSlug },
    },
  })

  if (!departmentCms && !sportsCms) {
    return {
      status: 301,
      props: null,
      headers: {
        location: `/404/?from=${slug}`,
      },
    }
  }

  if (departmentCms) {
    return {
      status: 200,
      props: {
        cmsData: departmentCms,
      },
      headers: {
        'cache-control': 'no-cache',
      },
    }
  }

  const { searchParams, searchResult } = await getSportsPageServerSideData(
    sportsCms.sections,
    newSlug,
    query
  )

  return {
    status: 200,
    props: {
      cmsData: sportsCms,
      searchParams,
      searchResult,
    },
    headers: {
      'cache-control': 'no-cache',
    },
  }
}

export default SportsPage
