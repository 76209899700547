import RichText from 'src/components/ui/RichText/RichText'

type TextComponentProps = {
  enableSession?: boolean
  text?: string
}

const ServicesTextComponent = ({ enableSession, text }: TextComponentProps) => {
  return (
    <>
      {!!enableSession && (
        <section>
          <RichText text={text} />
        </section>
      )}
    </>
  )
}

export default ServicesTextComponent
