import { useRef } from 'react'
import CloseIcon from 'src/images/icons/CloseIcon'
import { useClickOutside } from 'src/utils/useClickOutside'

interface CashbackMessageProps {
  handleCloseNotification: () => void
  onClick: () => void
  isMobile: boolean
}

export const CashbackMessage = ({
  handleCloseNotification,
  onClick,
  isMobile,
}: CashbackMessageProps) => {
  const notificationRef = useRef<HTMLDivElement>(null)

  useClickOutside(notificationRef, handleCloseNotification)

  return (
    <div
      className={`${
        isMobile ? 'left-0 top-12' : 'right-0 top-full'
      } absolute bg-white  z-10 px-6 py-7 max-w-[280px] max-h-[160px]`}
      ref={notificationRef}
    >
      <button
        className="absolute top-3 right-3"
        onClick={handleCloseNotification}
      >
        <CloseIcon />
      </button>
      <p className="font-medium mb-4 text-center text-base">
        Hey! Pode haver cashback esperando por você!
      </p>
      <button
        className="w-[232px] h-8 bg-restructure-background-information text-white font-semibold text-sm rounded-round"
        onClick={onClick}
      >
        Consultar meu saldo
      </button>
    </div>
  )
}
