import { Link } from 'gatsby'
import { Modal } from 'src/components/common/Modal'
import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'

import { Headphone } from '../Icons/Headphone'
import { ChevronRight } from '../Icons/ChevronRight'

type HelpModalProps = {
  isHelpModalOpen: boolean
  setIsHelpModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type ButtonProps = {
  buttonIcon: React.ReactNode
  buttonText: string
  buttonLink: string
}

const HelpButtonLink = ({
  buttonIcon,
  buttonText,
  buttonLink,
}: ButtonProps) => {
  return (
    <Link
      to={buttonLink}
      target="_blank"
      rel="noreferrer"
      className="w-full flex items-center justify-between px-4 border border-solid border-[#D9DDE1] h-[75px]"
    >
      <div className="flex items-center justify-center gap-4">
        {buttonIcon}
        <p className="text-[#101010] text-base">{buttonText}</p>
      </div>
      <ChevronRight color="#101010" height={16} width={16} />
    </Link>
  )
}

const HelpModal = ({ isHelpModalOpen, setIsHelpModalOpen }: HelpModalProps) => {
  const whatsappUrl =
    'https://api.whatsapp.com/send/?phone=5511947312606&text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista&gad_source=1&gclid=CjwKCAjwm_SzBhAsEiwAXE2Cv-3gLVZzcI9OInlqFCCYupiV36YeKMKYI7d3CLejL1pUP8GGsLLdwhoCMcYQAvD_BwE'

  return (
    <Modal
      isOpen={isHelpModalOpen}
      onClose={() => {
        setIsHelpModalOpen(false)
      }}
      opacityTailwind="opacity-80"
      className="rounded-md"
    >
      <div className="flex flex-col items-center justify-center pt-10 pb-8 px-4 w-[343px] gap-8 md:w-[375px]">
        <Button
          icon={<Icon name="BlackCloseIcon" width={24} height={24} />}
          iconPosition="right"
          onClick={() => {
            setIsHelpModalOpen(false)
          }}
          className="flex items-center justify-center absolute top-2 right-2 w-12 h-12"
        />
        <h2 className="px-8 text-[#15181B] text-xl font-medium text-center">
          Acesse nossos canais de atendimento
        </h2>
        <div className="w-full flex flex-col gap-4">
          <HelpButtonLink
            buttonLink={whatsappUrl}
            buttonIcon={<Headphone />}
            buttonText="Fale conosco pelo whatsapp"
          />
        </div>
      </div>
    </Modal>
  )
}

export default HelpModal
