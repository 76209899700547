/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { PageProps, GetServerDataProps } from 'gatsby'
import type {
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables,
} from '@generated/graphql'
import type { SearchState } from '@faststore/sdk'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import getCollectionServerSideData from 'src/utils/plp/getCollectionServerSideData'

type ServerData = {
  serverData: {
    searchParams: SearchState
    searchResult: SearchResult
  }
}

export type Props = PageProps<
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables,
  unknown,
  { seo: { title: string; description: string } }
> &
  ServerData

function Page(props: Props) {
  const {
    serverData: { searchParams, searchResult },
  } = props

  const title = `${searchResult?.collection?.name} | Decathlon`
  const description = searchResult?.collection?.description ?? ''

  const EVENT_API_BASE_PATH = 'https://sp.vtex.com'

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        language="pt-br"
        title={title ?? ''}
        description={description ?? ''}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
        linkTags={[
          {
            rel: 'preconnect',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
          {
            rel: 'dns-prefetch',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
        ]}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto">
        <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4 mb-6">
          <Breadcrumb
            type="single"
            breadcrumbList={[
              {
                item: `/colecao?q=${searchParams.term}`,
                name: searchParams.term ?? '',
                position: 1,
              },
            ]}
          />
        </div>
      </div>

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      <ProductGalleryV2
        title={searchResult?.collection?.name ?? ''}
        type="collection"
        searchResult={searchResult}
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchColecaoPage {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

export const getServerData = async ({ query }: GetServerDataProps) => {
  if (!query?.q) {
    return {
      status: 400,
      headers: {},
      props: {},
    }
  }

  try {
    const CACHE_CONTROL = `max-age=300, s-maxage=7200, stale-while-revalidate`

    const { searchParams, searchResult } = await getCollectionServerSideData({
      slug: 'colecao',
      query,
    })

    if (!searchResult?.collection) {
      return {
        status: 404,
        headers: {},
        props: {},
      }
    }

    return {
      status: 200,
      props: {
        searchParams,
        searchResult,
      },
      headers: {
        'cache-control': CACHE_CONTROL,
      },
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    }
  }
}

export default mark(Page)
