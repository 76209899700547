import type { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  color?: string
}

export function ChevronDown({ color = '#616161', ...props }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g id="chevron">
        <path
          id="Vector"
          d="M11.9999 13.172L16.9499 8.22205L18.3639 9.63605L11.9999 16L5.63586 9.63605L7.04986 8.22205L11.9999 13.172Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
