import type { SVGProps } from 'react'

const CloseIcon = ({
  width = '24',
  height = '24',
  fill = '#15181B',
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5303 5.46979C18.8232 5.76269 18.8232 6.23756 18.5303 6.53045L6.53033 18.5305C6.23744 18.8233 5.76256 18.8233 5.46967 18.5305C5.17678 18.2376 5.17678 17.7627 5.46967 17.4698L17.4697 5.46979C17.7626 5.1769 18.2374 5.1769 18.5303 5.46979Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46967 5.46979C5.76256 5.1769 6.23744 5.1769 6.53033 5.46979L18.5303 17.4698C18.8232 17.7627 18.8232 18.2376 18.5303 18.5305C18.2374 18.8233 17.7626 18.8233 17.4697 18.5305L5.46967 6.53045C5.17678 6.23756 5.17678 5.76269 5.46967 5.46979Z"
      fill={fill}
    />
  </svg>
)

export default CloseIcon
