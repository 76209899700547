import type { ComponentType } from 'react'
import { useEffect, lazy } from 'react'
import { graphql } from 'gatsby'
import type { GetServerDataProps } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import { useTheme } from 'src/components/contexts/ThemeContext'
import { Helmet } from 'react-helmet-async'
import { useLinxHomeView } from 'src/sdk/linx/hooks/useLinxHomeView'
import { RecommendationProvider } from 'src/components/restructure/product/contexts/recommendations-context'
import { HeroBanner } from 'src/components/home/HeroBanner'
import { Pictos } from 'src/components/common/Pictos'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { useAccountContext } from 'src/components/account/context/account-context'
import { updateOrRegisterAddress } from 'src/components/account/Profile/Tabs/components/addresses/utils/addressUtils'
import type { Addresses } from 'src/components/account/types'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { BlackFridayCountdown } from 'src/components/home/BlackFridayCountdown'
import { getCMSPage } from 'src/utils/getCMSPage'
import CashbackStripe from 'src/components/Cashback/CashbackStripe'

const GenericShelfv2 = lazy(
  () => import('src/components/restructure/product/shelves/GenericShelfV2')
)

const CollectionShelfv2 = lazy(
  () => import('src/components/restructure/product/shelves/CollectionShelfV2')
)

const TertiaryBanner = lazy(() => import('src/components/home/TertiaryBanner'))
const FindYourSport = lazy(() => import('src/components/home/FindYourSport'))
const GridBanner = lazy(() => import('src/components/home/GridBanner'))
const BannerOurBrands = lazy(
  () => import('src/components/restructure/product/sections/BannerOurBrands')
)

const COMPONENTS: Record<string, ComponentType<any>> = {
  'Nossas Marcas [Home]': BannerOurBrands,
  'Vitrine Linx [Home]': GenericShelfv2,
  'Banners Secundários [Home]': GridBanner,
  'Vitrine Editorial [Home]': CollectionShelfv2,
  'Encontre seu esporte [Home]': FindYourSport,
  'Banners Terciários [Home]': TertiaryBanner,
  'Hero Banner [Home]': HeroBanner,
  ' Pictos [Home]': Pictos,
  'BlackFriday Countdown': BlackFridayCountdown,
  '[Cashback] Stripe': CashbackStripe,
}

export interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string | null
        description: string | null
        titleTemplate: string | null
        siteUrl: string | null
      } | null
    } | null
  }
  serverData?: {
    cmsData?: {
      name: string
      sections?: any[]
    }
  }
}
function HomePage(props: Props) {
  const {
    data: { site },
  } = props

  const sections = props?.serverData?.cmsData?.sections

  const { theme, themes } = useTheme()
  const { sendHomeViewEvent } = useLinxHomeView()
  const { userDecathlon, updateUserDecathlon } = useAccountContext()

  const { orderForm } = useCheckout()

  const { homeBackground } = themes.filter((item) => item.key === theme)[0]
    .styles

  const title = site?.siteMetadata?.title ?? ''
  const siteUrl = site?.siteMetadata?.siteUrl ?? `https://www.decathlon.com.br`

  useEffect(() => {
    sendHomeViewEvent()
  }, [sendHomeViewEvent])

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    const { getAddresses } = userDecathlon

    const favoriteAddress = getAddresses.find(({ is_favorite }) => is_favorite)

    if (favoriteAddress !== undefined || !getAddresses.length) {
      return
    }

    const newFavorite = {
      ...getAddresses[0],
      is_favorite: true,
    } as Addresses

    const updateAddress = async () => {
      await updateOrRegisterAddress({
        action: 'update',
        orderFormId: orderForm.orderFormId ?? '',
        address: newFavorite,
        userDecathlon,
      })

      await updateUserDecathlon()
    }

    updateAddress()
  }, [orderForm, updateUserDecathlon, userDecathlon])

  return (
    <>
      <GatsbySeo
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        language="pt-br"
        canonical={siteUrl}
        openGraph={{
          type: 'website',
          url: siteUrl,
          title: title ?? '',
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${siteUrl}/pesquisa?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        }}
      />
      <Helmet>
        <meta
          name="google-site-verification"
          content="EWPzkQGZLqB-bFqzl2bpV8QIE7jUjrmm5Yyf5YXqghw"
        />
      </Helmet>
      <main className={`${homeBackground}`}>
        <RecommendationProvider pageName="home">
          {sections?.map(({ name, data }, index) => {
            const Component = COMPONENTS[name]

            if (!Component) {
              console.info(
                `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
              )

              return <></>
            }

            return (
              <Component
                key={`cms-section-${name}-${index}`}
                {...{ ...data, parentComponent: 'home', section: name }}
                type="homepage"
              />
            )
          })}
        </RecommendationProvider>
      </main>
    </>
  )
}

export const querySSG = graphql`
  query HomePageQuery2 {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
  }
`

export const getServerData = async ({ query }: GetServerDataProps) => {
  const isB2B = checkEnviromentIsB2B()

  const CMS_PAGE_ID = '783586c2-af79-46e7-a2a8-510929628b10'

  const CMS_VERSION_ID = (query?.versionId as string) ?? ''

  /**
   * documentId and versionId are used to fetch data for a specific page and version in the Vtex CMS.
   * Check: https://developers.vtex.com/docs/guides/faststore/headless-cms-previewing-changes-in-development-mode
   */
  return getCMSPage({
    contentType: 'home',
    documentId: CMS_PAGE_ID,
    versionId: CMS_VERSION_ID,
    builder: isB2B ? 'faststoreB2B' : 'faststore',
  })
}

HomePage.displayName = 'HomePage'
export default mark(HomePage)
