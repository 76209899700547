import Link from 'src/components/ui/Link'
import { BagCartOutline } from 'src/components/Icons/BagCartOutline'
import { Circle } from 'src/components/Icons/Circle'

type EmptyCartProps = {
  isMiniCart?: boolean
}

export function CartEmpty({ isMiniCart = false }: EmptyCartProps) {
  return (
    <section
      className={`flex flex-col justify-center items-center px-4 mx-auto sm:px-0 sm:max-w-[409px] ${
        !isMiniCart ? 'mt-10 sm:mt-12' : ''
      }`}
    >
      <div className="relative">
        <Circle
          color="#E1E4E7"
          className="absolute inset-0 w-full h-full rounded-full z-0"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <BagCartOutline
            color="#3643BA"
            className="restructure-small-desktop:w-lg restructure-small-desktop:h-lg"
          />
        </div>
      </div>
      <span
        className={`font-inter text-[20px]  text-[#15181B] mb-4 mt-[11px] no-underline ${
          !isMiniCart ? 'sm:text-[24px]' : ''
        }`}
      >
        Ops! Carrinho vazio
      </span>
      <div
        className={`flex flex-col items-center gap-1 text-tertiary font-inter text-[14px]  font-medium leading-4 no-underline text-center ${
          !isMiniCart ? 'sm:text-[16px] ' : ''
        }`}
      >
        {isMiniCart ? (
          <span>
            Pronto para escolher seu próximo esporte? Explore nossos produtos e
            aproveite o cashback!
          </span>
        ) : (
          <>
            <span>Pronto para escolher seu próximo esporte?</span>
            <span>Explore nossos produtos e aproveite o cashback!</span>
          </>
        )}
      </div>

      {!isMiniCart && (
        <div className="w-full">
          <Link
            className="w-full mt-[40px] sm:mt-[24px] h-[48px] flex items-center justify-center mb-6 bg-[#3643BA] !text-white text-[1rem] leading-[1.5rem] font-semibold rounded-full  hover:bg-[#323894] hover:!no-underline"
            href="/collection/?q=923"
          >
            Explorar Produtos
          </Link>
        </div>
      )}
    </section>
  )
}
