import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn: 'https://82e0741a868049fd5560c89250ce7bec@o4506859562205184.ingest.us.sentry.io/4506859619155968',
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^moz-extension:\/\//i,
  ],
  ignoreErrors: ['Non-Error promise rejection captured'],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['dkd-sentry-faststore-own-code'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
  environment: process.env.NODE_ENV,
  debug: false,
  enabled: true,
  sampleRate: 0.1, // Captura 10% dos eventos de erro
  tracesSampleRate: 0.05, // Captura 5% das transações de rastreamento (relacionadas ao desempenho)
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.2, // 20% de probabilidade de gravar um replay de sessão em casos de erro
})
