/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import useStorage from 'src/sdk/hooks/useStorage'

import { getUserSession } from './utils'
import type { Addresses, UserDecathlon } from '../types'

type AccountProviderProps = {
  children: ReactNode
}

type AccountContextProps = {
  isLoading: boolean
  userDecathlon: UserDecathlon | null
  setAlertSuccess: (item: boolean) => void
  updateUserDecathlon: () => Promise<void>
  alertSuccess: boolean
  setAlertError: (item: boolean) => void
  alertError: boolean
  cepValidation: boolean
  setCepValidation: (item: boolean) => void
  addressSelected: Addresses | undefined
  setAddressSelected: (item: Addresses | undefined) => void
  actionForm: any
  setActionForm: any
  cashBackBalance: number | null
  isLoggedIn: boolean | undefined
}

export const AccountContext = createContext<AccountContextProps>(
  {} as AccountContextProps
)

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { storageGetItem, storageSetItem } = useStorage()

  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>()
  const [userDecathlon, setUserDecathlon] = useState<UserDecathlon | null>(null)
  const [cashBackBalance, setCashBackBalance] = useState(null)

  const [alertError, setAlertError] = useState(false)
  const [alertSuccess, setAlertSuccess] = useState(false)

  const [addressSelected, setAddressSelected] = useState<
    Addresses | undefined
  >()

  const [actionForm, setActionForm] = useState(true)
  const [cepValidation, setCepValidation] = useState(false)

  useEffect(() => {
    const getUserSessionFn = async () => {
      const session = await getUserSession()

      setIsLoggedIn(session.isLoggedIn)
    }

    getUserSessionFn()
  }, [])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    const getCashback = async () => {
      const { data } = await axios.post('/api/getCashbackBalance', {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      })

      setCashBackBalance(data?.balance ?? 0)
    }

    getCashback()
  }, [isLoggedIn])

  useEffect(() => {
    const storageUserDecathlon = storageGetItem('user-decathlon', 'session')

    if (storageUserDecathlon) {
      return setUserDecathlon(JSON.parse(storageUserDecathlon))
    }

    if (!isLoggedIn) {
      return
    }

    setIsLoading(true)

    const getUserDecathlon = async () => {
      const { data } = await axios.post('/api/account/getUserDecathlon')

      setIsLoading(false)
      setUserDecathlon(data)
      storageSetItem('user-decathlon', JSON.stringify(data), 'session')
    }

    getUserDecathlon()
  }, [storageGetItem, storageSetItem, isLoggedIn])

  const updateUserDecathlon = async () => {
    const { data } = await axios.post('/api/account/getUserDecathlon')

    setUserDecathlon(data)
    storageSetItem('user-decathlon', JSON.stringify(data), 'session')
  }

  return (
    <AccountContext.Provider
      value={{
        isLoading,
        userDecathlon,
        setAlertSuccess,
        alertSuccess,
        alertError,
        setAlertError,
        setCepValidation,
        cepValidation,
        addressSelected,
        setAddressSelected,
        actionForm,
        setActionForm,
        updateUserDecathlon,
        cashBackBalance,
        isLoggedIn,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const useAccountContext = () => {
  return useContext(AccountContext)
}
