import SlideOver from 'src/components/ui/SlideOver'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import type { SetStateAction } from 'react'
import { Suspense, useState } from 'react'
import { UserOutline } from 'src/components/Icons/UserOutline'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { redirectToAccountOrLogin } from 'src/utils/redirectToAccountUrl'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import LogoMenuSVG from 'src/images/LogoMenuSVG'
import CloseIcon from 'src/images/icons/CloseIcon'

import MenuContent from './HeaderMobile/MenuContent'
import OpenMenuButton from './HeaderMobile/components/OpenMenuButton'

export interface SidebarMenuProps {
  handleCloseNotification: () => void
  showCashbackMessage: boolean
  onClickLogin: () => void
  badgeConfig?: {
    badgeValue: string
    badgeColor: string
    badgeTextColor: string
  }
}

const fastLinks = [
  {
    label: 'Favoritos',
    link: '/account',
  },
  {
    label: 'Nossos serviços',
    link: '/lojas',
  },
  {
    label: 'Decathlon empresas',
    link: 'https://www.decathlonpro.com.br/',
  },
  {
    label: 'Atendimento',
    link: '/atendimento',
  },
  {
    label: 'Clube Decathlon',
    link: '/club',
  },
  {
    label: 'Troca e devoluções',
    link: '/servicos/trocas-e-devolucoes/',
  },
]

export function SidebarMenu(props: SidebarMenuProps) {
  const location = useLocation()
  const isB2B = checkEnviromentIsB2B()

  const [isOpen, setIsOpen] = useState(false)

  function onCloseModal(value: SetStateAction<boolean>) {
    if (value) {
      setIsOpen(value)
      trackEvent()

      return
    }

    setIsOpen(value)
    document.firstElementChild?.classList.remove('no-scroll')
  }

  function trackEvent() {
    const event = makeEventTrack({
      eventAction: 'menu mobile',
      eventPage: location.pathname === '/' ? 'home' : location.pathname,
    })

    sendEvent(event)
  }

  return (
    <>
      <OpenMenuButton onClick={() => onCloseModal(true)} {...props} />

      <SlideOver
        direction="leftSide"
        isOpen={isOpen}
        onDismiss={() => onCloseModal(false)}
        size="full"
        className="flex flex-col"
      >
        <header className="flex items-center justify-between pt-lg pb-md px-md bg-restructure-background-primary">
          <LogoMenuSVG />
          <button
            className="w-lg h-lg"
            aria-label="botao para fechar o modal de menus do header"
            onClick={() => onCloseModal(false)}
          >
            <CloseIcon />
          </button>
        </header>

        <div className="overflow-y-auto">
          <div className="mt-xs px-md">
            <Link
              to={redirectToAccountOrLogin(isB2B)}
              className="flex gap-sm items-center bg-restructure-background-secondary rounded-md px-sm py-md"
            >
              <div className="flex items-center justify-center rounded-full bg-restructure-background-primary w-[40px] h-[40px]">
                <UserOutline />
              </div>

              <div className="flex flex-col ">
                <p className="desktop-body-regular-text3 text-restructure-primary">
                  Minha conta
                </p>
                <span className="desktop-caption-regular text-restructure-secondary">
                  Entre ou cadastre-se
                </span>
              </div>

              <span className="h-[48px] w-[48px] flex items-center justify-center ml-auto mr-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z"
                    fill="#15181B"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303C11.1768 19.2374 11.1768 18.7626 11.4697 18.4697L17.9393 12L11.4697 5.53033C11.1768 5.23744 11.1768 4.76256 11.4697 4.46967Z"
                    fill="#15181B"
                  />
                </svg>
              </span>
            </Link>
          </div>

          <div className="mt-md">
            <Suspense fallback={null}>
              <MenuContent setMenuIsOpen={(value) => onCloseModal(value)} />
            </Suspense>
          </div>

          <footer className="w-full bg-restructure-background-secondary px-md py-lg mt-auto mb-0">
            <h3 className="mobile-overline-medium text-restructure-tertiary">
              Acesso Rápido
            </h3>
            <ul className="mt-md flex flex-col gap-md">
              {fastLinks.map((item) => {
                if (isB2B && item.label === 'Decathlon empresas') {
                  return null
                }

                return (
                  <li
                    key={item.label}
                    className="mobile-body-regular-text2 text-restructure-primary"
                  >
                    <Link to={item.link} target="_blank" rel="noreferrer">
                      {item.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </footer>
        </div>
      </SlideOver>
    </>
  )
}
