import getYouTubeVideoID from 'src/utils/getYouTubeVideoID'

type ServicesVideoComponentProps = {
  enableSession: boolean
  videoLink: string
}

const ServicesVideoComponent = ({
  enableSession,
  videoLink,
}: ServicesVideoComponentProps) => {
  const videoId = getYouTubeVideoID(videoLink)

  if (!enableSession || !videoId) {
    return null
  }

  return (
    <section className="w-full h-[535px] max-w-[956px] mb-16">
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        width="100%"
        height="100%"
      />
    </section>
  )
}

export default ServicesVideoComponent
