import CloseIcon from 'src/images/icons/CloseIcon'

interface Item {
  id: string
  value: string
}

interface CollectionItensProps {
  list: any
  isShowDelete: boolean
  removeOption: (option: Item) => void
  blockInput: boolean
}

const CollectionItens: React.FC<CollectionItensProps> = ({
  list,
  isShowDelete,
  removeOption,
  blockInput,
}) => {
  return list.length > 0 ? (
    list.map((item: any) => (
      <div
        key={item.id}
        className={`w-full connection-mobile border-neutral04 border-[1px] mt-5 p-2 focus:outline-none ${
          blockInput && 'hover:border-[#a4adb7]'
        }  flex items-center justify-between py-5 px-4 ${
          blockInput ? 'bg-[#ffffff]' : 'bg-[#FAFAFA]'
        }`}
      >
        {item.value}

        {isShowDelete && blockInput && (
          <button
            type="button"
            className="ml-auto cursor-pointer"
            onClick={() => {
              removeOption(item)
            }}
            disabled={!blockInput}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    ))
  ) : !blockInput ? (
    <div className="w-full connection-mobile border-neutral04 border-[1px] mt-5 p-2 focus:outline-none bg-[#FAFAFA] flex items-center justify-between py-5 px-4">
      Sem seleção
    </div>
  ) : null
}

export default CollectionItens
