import './buttonSpinner.scss'

type ButtonSpinnerProps = {
  variant?: string
}
const ButtonSpinner = ({ variant = '' }: ButtonSpinnerProps) => {
  return <span className={`button-loader ${variant}`} />
}

export default ButtonSpinner
