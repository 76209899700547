import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

const isInternalLink = (url: string, baseOrigin: string): boolean => {
  if (url.startsWith('/')) {
    return true
  }

  try {
    const parsedUrl = new URL(url, baseOrigin)

    return parsedUrl.origin === baseOrigin
  } catch {
    return false
  }
}

const FlexibleLink = ({
  label,
  linkUrl,
  classNames,
  children,
}: {
  label?: string
  linkUrl: string
  classNames?: string
  children?: React.ReactNode
}) => {
  const { origin } = useLocation()

  return isInternalLink(linkUrl, origin) ? (
    <Link to={linkUrl} className={classNames}>
      {label}
      {children}
    </Link>
  ) : (
    <a
      href={linkUrl}
      className={classNames}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
      {children}
    </a>
  )
}

export default FlexibleLink
