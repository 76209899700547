import type { Item } from 'src/sdk/checkout/entity/orderForm'
import { SELLER_DECATHLON } from 'src/constants'

export function getStandardObject() {
  if (typeof document === 'undefined') {
    return null
  }

  const timestamp = new Date().toISOString().replace('T', ' ').replace('.', ' ')

  const timestampIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    'local timestamp': timestamp,
    'local timestamp identifier': timestampIdentifier,
    'website domain': document.location.origin,
    'page path': document.location.pathname,
    'page title': document.title,
    'page full url': document.location.href,
    'referring domain': document.referrer,
    'full referrer': null,
    'page name': document.location.pathname,
    'page type': checkPageType(),
    'page language': 'PT_BR',
    'previous page type': null,
    'previous page name': null,
    'page platform': 'WEB',
    'page number viewed': null,
  }
}

export function checkPageType(pathName?: string) {
  const pageMap = {
    '/checkout/cart': 'carrinho',
    '/account': 'perfil do cliente',
    '/pesquisa': 'plp',
    '/marcas': 'plp',
    '/collection': 'plp',
    '/colecao': 'plp',
    '/p': 'pdp',
  }

  for (const [key, value] of Object.entries(pageMap)) {
    if ((pathName ?? document.location.pathname).includes(key)) {
      return value
    }
  }

  return 'public'
}

export function checkCartType(items: Item[]) {
  const hasDecathlon = items.find((item) => item.seller === SELLER_DECATHLON)
  const hasMarketPlace = items.find((item) => item.seller !== SELLER_DECATHLON)

  switch (true) {
    case hasDecathlon && !hasMarketPlace:
      return 'decathlon'

    case hasMarketPlace && !hasDecathlon:
      return 'marketplace'

    default:
      return 'mixed'
  }
}
