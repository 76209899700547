import { memo, useMemo, useContext, useEffect, useRef } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import Icon from 'src/components/ui/Icon'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import { sendProductInformationSelectedEvent } from 'src/utils/amplitude/sendProductInformationSelectedEvent'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getProductQueryObject } from 'src/utils/amplitude/useProductObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import './styles.scss'

type Props = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

function ProductCharacteristics({ isOpen, onClose, onOpen }: Props) {
  const headerRef = useRef<HTMLHeadElement>(null)
  const { product, currentSku } = useContext(ProductContext)
  const { productBenefits } = product

  const standardObject = getStandardObject()
  const productObject = getProductQueryObject(currentSku)
  const featureDetailsObject = getFeatureObject({
    locationOnPage: null,
    name: 'Características',
    section: 'produto',
  })

  useEffect(() => {
    function hideImageWhenLoadingError() {
      const benefitImages = document.querySelectorAll(
        '.benefit-image'
      ) as unknown as HTMLImageElement[]

      benefitImages?.forEach((img) => {
        img.onerror = () => (img.style.display = 'none')
      })
    }

    hideImageWhenLoadingError()
  }, [isOpen])

  const EVENT_TRACK = useMemo(
    () =>
      makeEventTrack({
        eventAction: `caracteristicas - ${!isOpen}`,
        eventPage: 'pagina de produto',
      }),
    [isOpen]
  )

  return (
    <>
      {productBenefits && (
        <section
          className="bg-restructure-background-secondary mt-6 px-md py-md restructure-tablet:px-10 restructure-tablet:py-8 rounded-lg w-full max-w-[845px]"
          aria-label="Seção características do produto"
        >
          <header ref={headerRef}>
            <button
              data-testid="buttonCollapseCharacteristics"
              name="Características do produto"
              className="flex justify-between items-center w-full"
              onClick={() => {
                if (isOpen) {
                  onClose()
                } else {
                  sendProductInformationSelectedEvent({
                    featureDetailsObject,
                    standardObject,
                    productObject,
                    clickedElement: 'Características',
                  })
                  onOpen()
                  setTimeout(() => {
                    headerRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'start',
                    })
                  }, 200)
                }

                sendEvent(EVENT_TRACK)
              }}
            >
              <h3
                className="mobile-heading-title5 desktop-heading-title5 text-restructure-primary"
                aria-label="Título características do produto"
              >
                Características
              </h3>
              {isOpen ? (
                <Icon
                  aria-label="Botão para minimizar as características do produto"
                  data-testid="minusIconCharacteristics"
                  name="Minus"
                  width={32}
                  height={32}
                  fill="#15181B"
                />
              ) : (
                <Icon
                  aria-label="Botão para maximizar as características do produto"
                  data-testid="plusIconCharacteristics"
                  name="Plus"
                  width={32}
                  height={32}
                  fill="#15181B"
                />
              )}
            </button>
          </header>
          {isOpen && (
            <div
              aria-label="Descrição das características do produto"
              data-testid="productCharacteristics"
              className="product-benefits-content benefits
              mt-8
              pp:gap-y-6
              pp:grid pp:grid-cols-1
              md:grid md:grid-cols-2
              md:gap-y-10
              tablet:gap-y-8
              gap-x-10"
              dangerouslySetInnerHTML={{
                __html: productBenefits,
              }}
            />
          )}
        </section>
      )}
    </>
  )
}

export default memo(ProductCharacteristics)
