import { SearchProvider } from '@faststore/sdk'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import { ITEMS_PER_PAGE } from 'src/constants'
import Breadcrumb from 'src/components/ui/Breadcrumb/Breadcrumb'
import { applySearchState } from 'src/sdk/search/state'
import type {
  CollectionPageQueryQuery,
  ServerCollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'

type ServerData = {
  serverData: {
    searchParams: SearchState
    searchResult: SearchResult
    data: any
  }
  slug: string
  type?: string
}

export type Props = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery
> &
  ServerData

export function CollectionPageTemplate(props: Props) {
  const {
    serverData: { searchParams, searchResult, data },
    type,
  } = props

  const slug = data?.newURL?.split('/').filter(Boolean)
  const titleBySlug = slug ? slug[slug?.length - 1] : ''

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      <div className="layout__content mb-6">
        <Breadcrumb
          breadcrumbList={[
            {
              item: props.slug ?? '',
              name: '',
              position: 0,
            },
          ]}
        />
      </div>

      <ProductGalleryV2
        title={titleBySlug ?? searchParams?.term ?? ''}
        type="collection"
        dataSports={null}
        pageType={type}
        searchResult={searchResult}
      />
    </SearchProvider>
  )
}
