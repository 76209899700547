import { InView } from 'react-intersection-observer'
import { useAccountContext } from 'src/components/account/context'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { maskCurrency } from 'src/utils/convertCurrency'

export const CashbackUserInfo = () => {
  const { isLoggedIn, cashBackBalance } = useAccountContext()

  const showCashback = isLoggedIn && cashBackBalance && cashBackBalance > 0

  if (!showCashback) {
    return null
  }

  const sendPromoFeatureViewedEvent = () => {
    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        locationOnPage: null,
        name: 'cashback disponível',
        section: 'produto',
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Product Feature Viewed', eventData })
  }

  return (
    <InView
      as="div"
      threshold={0.7}
      triggerOnce
      onChange={(inView) => {
        if (inView) {
          sendPromoFeatureViewedEvent()
        }
      }}
    >
      <div className="flex items-center gap-1.5 bg-neutral02 p-1.5 my-3 w-fit">
        <div className="w-2 h-2 bg-restructure-border-action rounded-full" />
        <span className="text-sm">
          Você tem{' '}
          <span className="text-restructure-action">
            {maskCurrency(cashBackBalance)}
          </span>{' '}
          de cashback disponível
        </span>
      </div>
    </InView>
  )
}
