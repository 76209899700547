import { UserOutline } from 'src/components/Icons/UserOutline'
import { useMobile } from 'src/hooks/useMobile'

import LoginDropdown from '../LoginDropdown/LoginDropdown'
import { ButtonIcon } from '../ButtonIcon'
import { CashbackMessage } from '../Cashback/CashbackMessage'

interface LoginIconProps {
  showLoginDropdown: boolean
  showCashbackMessage: boolean
  handleCloseNotification: () => void
  onClick: () => void
  badgeConfig?: {
    badgeValue: string
    badgeColor: string
    badgeTextColor: string
  }
}

export const LoginButton = ({
  showLoginDropdown,
  showCashbackMessage,
  handleCloseNotification,
  onClick,
  badgeConfig,
}: LoginIconProps) => {
  const { isMobile } = useMobile()

  return (
    <>
      <div className="bg-white">
        <ButtonIcon
          onClick={onClick}
          enableHover={!showCashbackMessage}
          {...badgeConfig}
        >
          <UserOutline
            aria-label="Botão para redirecionar para a pagina de my-account"
            data-testid="user-icon-button"
          />
        </ButtonIcon>
      </div>

      {showCashbackMessage && !isMobile && (
        <CashbackMessage
          isMobile={false}
          handleCloseNotification={handleCloseNotification}
          onClick={onClick}
        />
      )}

      {showLoginDropdown && !showCashbackMessage && (
        <LoginDropdown className="absolute top-full shadow-dropCard left-1/2 bg-white rounded-lg min-w-[202px] -translate-x-1/2 z-10" />
      )}
    </>
  )
}
