interface OverlayProps {
  show: boolean
}

export const Overlay = ({ show }: OverlayProps) => {
  return (
    <div
      id="overlay"
      className={`${
        show ? 'opacity-75' : 'hidden'
      } z-[100000] inset-0 fixed w-full h-full transition-all duration-300 ease-out bg-restructure-background-neutral-12`}
    />
  )
}
