import { useEffect, useState } from 'react'
import { useAccountContext } from 'src/components/account/context'
import { useLocation } from '@reach/router'

import MaybeCashback from './MaybeCashback'
import CashbackStripeLogedIn from './CashbackStripeLogedIn'
import CashbackPopup from './CashbackPopup'

type CashbackStripeProps = {
  cashbackStipeMainText: string
  cashbackStipeSecondText: string
  enableSession: boolean
  showStripe: boolean
  ShowStripe: boolean
}

export default function CashbackStripe({
  cashbackStipeMainText,
  cashbackStipeSecondText,
  enableSession,
}: CashbackStripeProps) {
  const { search, pathname } = useLocation()
  const { isLoggedIn, cashBackBalance } = useAccountContext()
  const [hasViewedCashbackInfo, setHasViewedCashbackInfo] = useState(false)
  const [showCashbackPopup, setShowCashbackPopup] = useState(false)
  const isCheckout = pathname.includes('checkout')

  useEffect(() => {
    setHasViewedCashbackInfo(!!sessionStorage.getItem('cashback-stripe'))
  }, [search])

  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const hasViwedCashbackPopup = !!sessionStorage.getItem('cashback-popup')

    const showCashbackPopUp =
      urlParams.has('showcashbackpopup') &&
      !!isLoggedIn &&
      cashBackBalance === 0 &&
      !hasViwedCashbackPopup

    setShowCashbackPopup(showCashbackPopUp)
  }, [cashBackBalance, isLoggedIn, search])

  const hancleClosePopup = () => {
    sessionStorage.setItem('cashback-popup', 'true')
    setShowCashbackPopup(false)
  }

  if (
    !enableSession ||
    hasViewedCashbackInfo ||
    isLoggedIn === undefined ||
    isCheckout
  ) {
    return null
  }

  if (showCashbackPopup) {
    return <CashbackPopup hancleClosePopup={hancleClosePopup} />
  }

  return isLoggedIn ? (
    <CashbackStripeLogedIn showStripe={pathname === '/'} />
  ) : (
    <MaybeCashback
      cashbackStipeMainText={cashbackStipeMainText}
      cashbackStipeSecondText={cashbackStipeSecondText}
    />
  )
}
