/**
 * Extracts the YouTube video ID from a given URL.
 *
 * Supports various YouTube URL formats, including:
 * - Standard: https://www.youtube.com/watch?v=VIDEO_ID
 * - Short: https://youtu.be/VIDEO_ID
 * - Embed: https://www.youtube.com/embed/VIDEO_ID
 *
 * @param {string} url - The YouTube video URL.
 * @returns {string | null} The video ID if found, or `null` if the URL is invalid.
 *
 * @example
 * getYouTubeVideoID("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
 * // Returns: "dQw4w9WgXcQ"
 *
 * @example
 * getYouTubeVideoID("https://youtu.be/dQw4w9WgXcQ");
 * // Returns: "dQw4w9WgXcQ"
 */
export default function getYouTubeVideoID(url: string) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/

  const match = url.match(regex)

  return match ? match[1] : null
}
