/* eslint-disable @typescript-eslint/no-explicit-any */
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useState, Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet-async'
import { SearchProvider } from '@faststore/sdk'
import { applySearchState } from 'src/sdk/search/state'
import { ITEMS_PER_PAGE } from 'src/constants'
import { getCMSPageByContentType } from 'src/utils/getCMSPageDataByContentType'
// COMPONENTS
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import GridSkeleton from 'src/components/sports/utils/skeleton/banners/banners'
import GridCategoriesSkeleton from 'src/components/sports/utils/skeleton/categories/categories'
import TitlePage from 'src/components/sports/titlePage/titlePage'
import { RelatedResearch } from 'src/components/sports/RelatedResearch/RelatedResearch'
import { ContentSEO } from 'src/components/sports/ContentSEO'
// TYPES
import type { PropsCMS } from 'src/components/sports/utils/types'
import type { SearchState } from '@faststore/sdk'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'
import Breadcrumb from 'src/components/ui/Breadcrumb/Breadcrumb'

const CategoriesSlider = lazy(
  () => import('src/components/sports/categoriesSlider/categoriesSlider')
)

const BannersSlider = lazy(
  () => import('src/components/sports/bannersSlider/bannersSlider')
)

type ServerData = {
  serverData: {
    searchParams: SearchState
    searchResult: SearchResult
  }
}

export function SportsPageTemplate(props: PropsCMS & ServerData) {
  const { serverData, type } = props
  const { cmsData, searchParams, searchResult } = serverData
  const sections = cmsData?.sections

  const [showCarrosselBanners, setShowCarrosselBanners] = useState<any>()
  const [showCarrosselCategories, setShowCarrosselCategories] = useState<any>()
  const [showProductGallery, setShowProductGallery] = useState<boolean>(false)
  const [paramsSports, setParamsSports] = useState<string>('')

  useEffect(() => {
    const dataCarrosselBanners = sections?.filter(
      (item: any) =>
        item.name === '5.2 Carrossel de banners simples' &&
        (item.data.configuration === 'exibir website e aplicativo' ||
          item.data.configuration === 'exibir apenas website')
    )

    if (dataCarrosselBanners?.length) {
      setShowCarrosselBanners(dataCarrosselBanners[0].data)
    } else {
      setShowCarrosselBanners(null)
    }

    const dataCarrosselCategories = sections?.filter(
      (item: any) =>
        item.name === '5.3 Carrossel de navegação rápida com ícones' &&
        (item.data.configuration === 'exibir website e aplicativo' ||
          item.data.configuration === 'exibir apenas website')
    )

    if (dataCarrosselCategories?.length) {
      setShowCarrosselCategories(dataCarrosselCategories[0].data)
    } else {
      setShowCarrosselCategories(null)
    }

    const dataProductGallery = sections?.filter(
      (item: any) =>
        item.name === '5.4 Listagem de produtos via BFF' &&
        (item.data.configuration === 'exibir website e aplicativo' ||
          item.data.configuration === 'exibir apenas website')
    )

    if (dataProductGallery?.length) {
      setParamsSports(dataProductGallery[0].data.URiWeb)
      setShowProductGallery(true)
    } else {
      setShowProductGallery(false)
      setParamsSports('')
    }
  }, [
    showCarrosselBanners,
    showCarrosselCategories,
    showProductGallery,
    paramsSports,
    sections,
  ])

  const siteMetadataWithSlug = cmsData?.seo.siteMetadataWithSlug

  if (!searchParams) {
    return null
  }

  if (serverData === null) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      <Helmet>
        <meta
          name="keywords"
          content={
            siteMetadataWithSlug.keywords ? siteMetadataWithSlug.keywords : ''
          }
        />
        <meta
          property="og:image"
          content={
            siteMetadataWithSlug.ogImage ? siteMetadataWithSlug.ogImage : ''
          }
        />
      </Helmet>

      <GatsbySeo
        title={siteMetadataWithSlug.title ? siteMetadataWithSlug.title : ''}
        description={
          siteMetadataWithSlug.description
            ? siteMetadataWithSlug.description
            : ''
        }
        titleTemplate={
          siteMetadataWithSlug.titleTemplate
            ? siteMetadataWithSlug.titleTemplate
            : ''
        }
        language="pt-br"
        canonical={`https://www.decathlon.com.br/esportes/${
          siteMetadataWithSlug.slug ? siteMetadataWithSlug.slug : ''
        }`}
        openGraph={{
          type: 'website',
          url: `https://www.decathlon.com.br/esportes/${
            siteMetadataWithSlug.slug ? siteMetadataWithSlug.slug : ''
          }`,
          title: `${
            siteMetadataWithSlug.title ? siteMetadataWithSlug.title : ''
          }`,
          description: `${
            siteMetadataWithSlug.description
              ? siteMetadataWithSlug.description
              : ''
          }`,
        }}
      />

      {/* BREADCRUMB */}
      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4">
        <Breadcrumb
          breadcrumbList={[
            {
              item: `/esportes/${props?.slug ?? ''}/`,
              name: props?.slug ?? '',
              position: 1,
            },
          ]}
          type="single"
        />
      </div>

      {/* content first page */}
      <TitlePage data={cmsData?.sections} />

      {/* banners slider */}
      {showCarrosselBanners && (
        <Suspense fallback={<GridSkeleton loading />}>
          <BannersSlider data={showCarrosselBanners} />
        </Suspense>
      )}

      {/* categories slider */}
      {showCarrosselCategories && (
        <Suspense fallback={<GridCategoriesSkeleton loading />}>
          <CategoriesSlider data={showCarrosselCategories} />
        </Suspense>
      )}

      {/* VITRINE */}
      {searchParams.selectedFacets.length ? (
        <ProductGalleryV2
          dataSports={cmsData}
          title="Esportes"
          type="search"
          pageType={type}
          searchResult={searchResult}
        />
      ) : (
        <div className="layout__content mb-8" style={{ marginTop: '-40px' }}>
          {cmsData && <RelatedResearch dataCMS={cmsData} />}
          {cmsData && <ContentSEO dataCMS={cmsData} />}
        </div>
      )}
    </SearchProvider>
  )
}

export const getServerData = async ({
  // query,
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) =>
  getCMSPageByContentType({
    slug,
    contentType: 'esportes',
  })
