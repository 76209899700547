import { useRef } from 'react'
import useClickAway from 'react-use/lib/useClickAway'
import CloseIcon from 'src/images/icons/CloseIcon'
import { useModalSheet } from 'src/components/contexts/ModalSheetContext'
import { useMobile } from 'src/hooks/useMobile'

import { Overlay } from '../Overlay/Overlay'

export const ModalSheet = () => {
  const { isMobile } = useMobile()
  const ref = useRef(null)

  useClickAway(ref, () => closeModal())

  const { isModalOpen, closeModal, modalConfig } = useModalSheet()

  if (!modalConfig) {
    return null
  }

  const {
    content,
    height = '570px',
    position = isMobile ? 'bottom' : 'right',
    title,
  } = modalConfig

  const transitionClasses = `transition-all duration-300 ease-out ${
    !isModalOpen &&
    `${position === 'bottom' ? 'translate-y-full' : 'translate-x-full'}`
  }`

  return (
    <div
      className={`${
        isModalOpen ? 'visible' : 'invisible'
      } fixed top-0 bottom-0 left-0 right-0 z-[9999] inset-0`}
    >
      <Overlay show={isModalOpen} />
      <div
        ref={ref}
        className={`${
          position === 'bottom' ? `h-[${height}]` : ''
        } ${position}-0 w-full bg-white absolute sm:h-full sm:w-[485px] overflow-y-auto overflow-x-hidden ${transitionClasses} z-[99999999]`}
      >
        <div className="w-full p-7 flex justify-between">
          <span className="text-xl sm:text-2xl font-medium">{title}</span>
          <button onClick={() => closeModal()}>
            <CloseIcon />
          </button>
        </div>
        {content}
      </div>
    </div>
  )
}
