import type { FormEvent } from 'react'
import { useContext, useState } from 'react'
import { RegisterSellerContext } from 'src/components/contexts/registerSellerContext'
import { validCepFormat } from 'src/components/product/SelectShipping/modules/validCep'
import { getStorage } from 'src/utils/storage'
import {
  autoFillWithCEP,
  createFallbackInMDv2ToCaptureSeller,
  createOrg,
  createPerson,
} from 'src/utils/reqApi'
import { maskFone, ufList } from 'src/utils/masks'
import {
  convertStringToNumber,
  replaceCurrencyForString,
} from 'src/utils/convertCurrency'

import type {
  ErrorReportPropsStep3,
  RegisterSellerStepFinalProps,
  SellerRegisterStep3,
} from './typings'
import InputFormSeller from './InputFormSeller'

// eslint-disable-next-line no-useless-escape
const REGEX_VALID_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const INITIAL_VALUE_ERROR = {
  cep: '',
  street: '',
  number: '',
  neighborhood: '',
  city: '',
  state: '',
  fone1: '',
  fone2: '',
  responsible: '',
  mailResponsibleMarketplace: '',
  mailResponsibleSac: '',
}

function messageForUserAboutInfoRequired(
  name: string,
  setErrorReport: React.Dispatch<React.SetStateAction<any>>
) {
  setErrorReport((prevState: any) => ({
    ...prevState,
    [name]: 'Campo obrigatório',
  }))
}

function ValidFormAndNextStep(
  seller: any,
  setErrorReport: React.Dispatch<React.SetStateAction<any>>
) {
  let isValid = true

  if (!seller.cep) {
    isValid = false
    messageForUserAboutInfoRequired('cep', setErrorReport)
  }

  if (!seller.street) {
    isValid = false
    messageForUserAboutInfoRequired('street', setErrorReport)
  }

  if (!seller.number) {
    isValid = false
    messageForUserAboutInfoRequired('number', setErrorReport)
  }

  if (!seller.neighborhood) {
    isValid = false
    messageForUserAboutInfoRequired('neighborhood', setErrorReport)
  }

  if (!seller.city) {
    isValid = false
    messageForUserAboutInfoRequired('city', setErrorReport)
  }

  if (seller.state === 'Selecione') {
    isValid = false
    messageForUserAboutInfoRequired('state', setErrorReport)
  }

  if (!seller.fone1) {
    isValid = false
    messageForUserAboutInfoRequired('fone1', setErrorReport)
  }

  if (seller.fone1.length < 14) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      fone1: 'Número inválido',
    }))
  }

  if (seller.fone2) {
    if (seller.fone2.length < 14) {
      isValid = false
      setErrorReport((prevState: any) => ({
        ...prevState,
        fone2: 'Número inválido',
      }))
    }
  }

  if (!seller.responsible) {
    isValid = false
    messageForUserAboutInfoRequired('responsible', setErrorReport)
  }

  if (!seller.mailResponsibleMarketplace) {
    isValid = false
    messageForUserAboutInfoRequired(
      'mailResponsibleMarketplace',
      setErrorReport
    )
  } else if (!REGEX_VALID_EMAIL.test(seller.mailResponsibleMarketplace)) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      mailResponsibleMarketplace: 'Email inválido',
    }))
  }

  if (!seller.mailResponsibleSac) {
    isValid = false
    messageForUserAboutInfoRequired('mailResponsibleSac', setErrorReport)
  } else if (!REGEX_VALID_EMAIL.test(seller.mailResponsibleSac)) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      mailResponsibleSac: 'Email inválido',
    }))
  }

  return isValid
}

export default function RegisterSellerStep3({
  formStep,
  setFormStep,
  setShowModal,
  setContentModal,
  setIsError,
}: RegisterSellerStepFinalProps) {
  const { businessInfo } = useContext(RegisterSellerContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorReport, setErrorReport] =
    useState<ErrorReportPropsStep3>(INITIAL_VALUE_ERROR)

  const [seller, setSeller] = useState<SellerRegisterStep3>({
    cep: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: 'Selecione',
    fone1: '',
    fone2: '',
    responsible: '',
    mailResponsibleMarketplace: '',
    mailResponsibleSac: '',
  })

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const isValidForm = ValidFormAndNextStep(seller, setErrorReport)

    if (!isValidForm) {
      setIsError(true)

      return
    }

    setIsError(false)
    setErrorReport(INITIAL_VALUE_ERROR)
    const storageIntegradora = getStorage('integradora')
    const storageCategory = getStorage('category')
    const newBusinessInfo = {
      ...businessInfo,
      ...seller,
      faturamento: convertStringToNumber(
        replaceCurrencyForString(businessInfo.faturamento)
      ),
      productsQuantity: parseFloat(businessInfo.productsQuantity),
      ticketValuetion: convertStringToNumber(
        replaceCurrencyForString(businessInfo.ticketValuetion)
      ),
      address: `${seller.street}, ${seller.number} - ${seller.neighborhood}, ${seller.city} - ${seller.state}, ${seller.cep}`,
      categoria: storageCategory as string,
      integradora: storageIntegradora as string,
    }

    try {
      const organization = await createOrg({
        businessInfo: newBusinessInfo,
        endpoint: `/api/seller/addOrganization`,
      })

      setIsLoading(true)

      if (organization.success) {
        const {
          data: { id: idOrg },
        } = organization

        const reqCreatePerson = await createPerson({ seller, idOrg })

        if (reqCreatePerson.success) {
          const {
            data: { id: idPerson },
          } = reqCreatePerson

          const createLead = await fetch('/api/seller/addLead', {
            method: 'POST',
            body: JSON.stringify({
              title: businessInfo.razaoSocial,
              person_id: idPerson,
              organization_id: idOrg,
              dd2b19c659893c7fbe8854addf3a50cb51cebdf6: 172,
            }),
          }).then((res) => res.json())

          if (createLead.success) {
            setIsLoading(false)
            setContentModal({
              title: 'Recebemos seus dados',
              description:
                'Obrigado pelo interesse em fazer parte da Decathlon! \nEm breve alguém do nosso time entrará em contato!',
            })
            setShowModal(true)

            return
          }
        }
      }

      setIsLoading(false)
      setContentModal({
        title: 'Recebemos seus dados',
        description:
          'Obrigado pelo interesse em fazer parte da Decathlon! \nEm breve alguém do nosso time entrará em contato!',
      })

      setShowModal(true)

      await createFallbackInMDv2ToCaptureSeller(newBusinessInfo)
    } catch (err) {
      createFallbackInMDv2ToCaptureSeller(newBusinessInfo)
      console.error(err)
    }
  }

  return (
    <>
      <section className="containerForm containerFormStep3">
        <form onSubmit={(e) => handleSubmit(e)} className="containerForm-form">
          <InputFormSeller errorReport={errorReport} name="cep" text="CEP*">
            <input
              id="cep"
              type="text"
              name="cep"
              placeholder="00000-000"
              className="sellerRegisterInput"
              value={seller.cep}
              onBlur={({ target }) => {
                target.value = target.value.replace(/[^0-9]/g, '')
                validCepFormat(target.value)
                  .then((res) => {
                    if (res === true) {
                      autoFillWithCEP({
                        seller,
                        setSeller,
                        setErrorReport,
                      })
                    } else {
                      setErrorReport({
                        ...errorReport,
                        cep: 'CEP inválido',
                      })
                    }
                  })
                  .catch(() => {
                    setErrorReport({
                      ...errorReport,
                      cep: 'CEP inválido',
                    })
                  })
              }}
              onChange={({ target }) => {
                if (target.value.length > 9) {
                  return
                }

                target.value = target.value
                  .replace(/\D/g, '')
                  .replace(/^(\d{5})(\d{3})+?$/, '$1-$2')

                setSeller({ ...seller, cep: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller errorReport={errorReport} name="street" text="Rua*">
            <input
              id="street"
              type="text"
              name="street"
              placeholder="Insira aqui sua rua"
              className="sellerRegisterInput"
              value={seller.street}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (/[\W(_)]/.test(target.value)) {
                  target.value = target.value.replace(/[\W(_)]/, '')
                } else if (target.value.length > 100) {
                  return
                } else if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    street: '',
                  })
                }

                setSeller({ ...seller, street: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="number"
            text="Número*"
          >
            <input
              id="number"
              type="text"
              name="number"
              placeholder="0"
              className="sellerRegisterInput"
              value={seller.number}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (/[\W(_)]/.test(target.value)) {
                  target.value = target.value.replace(/[\W(_)]/, '')
                } else if (target.value.length > 10) {
                  return
                } else if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    number: '',
                  })
                }

                setSeller({ ...seller, number: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="neighborhood"
            text="Bairro*"
          >
            <input
              id="neighborhood"
              type="text"
              name="neighborhood"
              placeholder="Insira aqui seu bairro"
              className="sellerRegisterInput"
              value={seller.neighborhood}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (/[\W(_)]/.test(target.value)) {
                  target.value = target.value.replace(/[\W(_)]/, '')
                } else if (target.value.length > 50) {
                  return
                } else if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    neighborhood: '',
                  })
                }

                setSeller({ ...seller, neighborhood: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller errorReport={errorReport} name="city" text="Cidade*">
            <input
              id="city"
              type="text"
              name="city"
              placeholder="Insira aqui seu estado"
              className="sellerRegisterInput"
              value={seller.city}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (/[\W(_)]/.test(target.value)) {
                  target.value = target.value.replace(/[\W(_)]/, '')
                } else if (target.value.length > 50) {
                  return
                } else if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    city: '',
                  })
                }

                setSeller({ ...seller, city: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="state"
            text="Estado*"
          >
            <select
              id="state"
              name="state"
              placeholder="Insira aqui seu estado"
              className="sellerRegisterInput"
              value={seller.state}
              onChange={({ target }) => {
                setSeller({ ...seller, state: target.value })
              }}
            >
              {ufList.map((uf) => (
                <option key={uf}>{uf}</option>
              ))}
            </select>
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="fone1"
            text="Telefone 1*"
          >
            <input
              id="fone1"
              type="text"
              name="fone1"
              placeholder="(00) (00000 - 0000)"
              className="sellerRegisterInput"
              value={seller.fone1}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (target.value.length > 15) {
                  return
                }

                if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    fone1: '',
                  })
                }

                target.value = maskFone(target.value)
                setSeller({ ...seller, fone1: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="fone2"
            text="Telefone 2"
          >
            <input
              id="fone2"
              type="text"
              name="fone2"
              placeholder="(00) (00000 - 0000)"
              className="sellerRegisterInput"
              value={seller.fone2}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (target.value.length > 15) {
                  return
                }

                target.value = maskFone(target.value)
                setSeller({ ...seller, fone2: target.value })
              }}
            />
          </InputFormSeller>

          <InputFormSeller
            errorReport={errorReport}
            name="responsible"
            text="Nome do Responsável para Contato*"
          >
            <input
              id="responsible"
              type="text"
              name="responsible"
              placeholder="Nome Completo"
              className="sellerRegisterInput"
              value={seller.responsible}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (target.value.length > 50) {
                  return
                }

                if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    responsible: '',
                  })
                }

                setSeller({ ...seller, responsible: target.value })
              }}
            />
          </InputFormSeller>

          <InputFormSeller
            errorReport={errorReport}
            name="mailResponsibleMarketplace"
            text="Email do Responsável Marketplace*"
          >
            <input
              id="mailResponsibleMarketplace"
              type="text"
              name="mailResponsibleMarketplace"
              className="sellerRegisterInput"
              placeholder="name@example.com"
              value={seller.mailResponsibleMarketplace}
              onBlur={({ target }) => {
                const isValid = REGEX_VALID_EMAIL.test(target.value)

                if (isValid) {
                  setErrorReport({
                    ...errorReport,
                    mailResponsibleMarketplace: '',
                  })
                }
              }}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (target.value.length > 70) {
                  return
                }

                setSeller({
                  ...seller,
                  mailResponsibleMarketplace: target.value,
                })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="mailResponsibleSac"
            text="Email do Responsável Atendimento (SAC)*"
          >
            <input
              id="mailResponsibleSac"
              type="text"
              name="mailResponsibleSac"
              className="sellerRegisterInput"
              placeholder="name@example.com"
              value={seller.mailResponsibleSac}
              onBlur={({ target }) => {
                const isValid = REGEX_VALID_EMAIL.test(target.value)

                if (isValid) {
                  setErrorReport({
                    ...errorReport,
                    mailResponsibleSac: '',
                  })
                }
              }}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (target.value.length > 70) {
                  return
                }

                setSeller({ ...seller, mailResponsibleSac: target.value })
              }}
            />
          </InputFormSeller>
          <section className="containerButtons">
            {isLoading ? (
              <button type="submit" className="buttonSubmitForm">
                <div className="spinner" />
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="backStepButton"
                  onClick={() => setFormStep(formStep - 1)}
                >
                  Voltar
                </button>
                <button type="submit" className="buttonSubmitForm">
                  ENVIAR DADOS
                </button>
              </>
            )}
          </section>
        </form>
      </section>
    </>
  )
}
